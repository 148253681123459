import { ReactElement } from 'react';

export const CemeteryTours = (): ReactElement => {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50 100C59.889 100 69.556 97.0676 77.7785 91.5735C86.0009 86.0794 92.4096 78.2705 96.1939 69.1342C99.9783 59.9979 100.969 49.9445 99.0392 40.2455C97.11 30.5464 92.3479 21.6373 85.3553 14.6447C78.3627 7.65206 69.4535 2.89002 59.7545 0.960758C50.0555 -0.968502 40.0021 0.0216641 30.8658 3.80604C21.7295 7.59043 13.9206 13.9991 8.42651 22.2215C2.93244 30.444 0 40.1109 0 50C0 63.2608 5.26783 75.9785 14.6446 85.3553C24.0215 94.7322 36.7392 100 50 100Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50 34.75C41.5777 34.75 34.75 41.5777 34.75 50V70H33.25V50C33.25 40.7492 40.7492 33.25 50 33.25C59.2508 33.25 66.75 40.7492 66.75 50V70H65.25V50C65.25 41.5777 58.4223 34.75 50 34.75Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50 41.75C46.5482 41.75 43.75 44.5482 43.75 48C43.75 51.4518 46.5482 54.25 50 54.25C53.4518 54.25 56.25 51.4518 56.25 48C56.25 44.5482 53.4518 41.75 50 41.75ZM42.25 48C42.25 43.7198 45.7198 40.25 50 40.25C54.2802 40.25 57.75 43.7198 57.75 48C57.75 52.2802 54.2802 55.75 50 55.75C45.7198 55.75 42.25 52.2802 42.25 48Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.043 60.166C44.043 59.7518 44.3788 59.416 44.793 59.416H55.3497C55.7639 59.416 56.0997 59.7518 56.0997 60.166C56.0997 60.5802 55.7639 60.916 55.3497 60.916H44.793C44.3788 60.916 44.043 60.5802 44.043 60.166Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M41.4043 64.4648C41.4043 64.0506 41.7401 63.7148 42.1543 63.7148H57.9893C58.4036 63.7148 58.7393 64.0506 58.7393 64.4648C58.7393 64.8791 58.4036 65.2148 57.9893 65.2148H42.1543C41.7401 65.2148 41.4043 64.8791 41.4043 64.4648Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.25 70.6598C25.25 69.8812 25.8812 69.25 26.6598 69.25H73.3402C74.1188 69.25 74.75 69.8812 74.75 70.6598V77.3402C74.75 78.1188 74.1188 78.75 73.3402 78.75H26.6598C25.8812 78.75 25.25 78.1188 25.25 77.3402V70.6598ZM26.75 70.75V77.25H73.25V70.75H26.75Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M47.343 14.6598C47.343 13.8812 47.9742 13.25 48.7528 13.25H51.3919C52.1706 13.25 52.8017 13.8812 52.8017 14.6598V17.5387H56.3404C57.119 17.5387 57.7502 18.1698 57.7502 18.9485V21.5876C57.7502 22.3662 57.119 22.9974 56.3404 22.9974H52.8017V29.835C52.8017 30.6136 52.1706 31.2448 51.3919 31.2448H48.7528C47.9742 31.2448 47.343 30.6136 47.343 29.835V22.9974H43.8043C43.0257 22.9974 42.3945 22.3662 42.3945 21.5876V18.9485C42.3945 18.1698 43.0257 17.5387 43.8043 17.5387H47.343V14.6598ZM48.843 14.75V18.2887C48.843 18.7029 48.5072 19.0387 48.093 19.0387H43.8945V21.4974H48.093C48.5072 21.4974 48.843 21.8332 48.843 22.2474V29.7448H51.3017V22.2474C51.3017 21.8332 51.6375 21.4974 52.0517 21.4974H56.2502V19.0387H52.0517C51.6375 19.0387 51.3017 18.7029 51.3017 18.2887V14.75H48.843Z"
                fill="#0DD1C5"
            />
            <path
                d="M80.87 52.62H79.55V50.75C79.55 50.5511 79.471 50.3603 79.3303 50.2197C79.1897 50.079 78.9989 50 78.8 50C78.6011 50 78.4103 50.079 78.2697 50.2197C78.129 50.3603 78.05 50.5511 78.05 50.75V52.62H76.75C76.5511 52.62 76.3603 52.699 76.2197 52.8397C76.079 52.9803 76 53.1711 76 53.37C76 53.5689 76.079 53.7597 76.2197 53.9003C76.3603 54.041 76.5511 54.12 76.75 54.12H78.05V56C78.05 56.1989 78.129 56.3897 78.2697 56.5303C78.4103 56.671 78.6011 56.75 78.8 56.75C78.9989 56.75 79.1897 56.671 79.3303 56.5303C79.471 56.3897 79.55 56.1989 79.55 56V54.12H80.87C81.0689 54.12 81.2597 54.041 81.4003 53.9003C81.541 53.7597 81.62 53.5689 81.62 53.37C81.62 53.1711 81.541 52.9803 81.4003 52.8397C81.2597 52.699 81.0689 52.62 80.87 52.62Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M27.87 32.62H26.55V30.75C26.55 30.5511 26.471 30.3603 26.3303 30.2197C26.1897 30.079 25.9989 30 25.8 30C25.6011 30 25.4103 30.079 25.2697 30.2197C25.129 30.3603 25.05 30.5511 25.05 30.75V32.62H23.75C23.5511 32.62 23.3603 32.699 23.2197 32.8397C23.079 32.9803 23 33.1711 23 33.37C23 33.5689 23.079 33.7597 23.2197 33.9003C23.3603 34.041 23.5511 34.12 23.75 34.12H25.05V36C25.05 36.1989 25.129 36.3897 25.2697 36.5303C25.4103 36.671 25.6011 36.75 25.8 36.75C25.9989 36.75 26.1897 36.671 26.3303 36.5303C26.471 36.3897 26.55 36.1989 26.55 36V34.12H27.87C28.0689 34.12 28.2597 34.041 28.4003 33.9003C28.541 33.7597 28.62 33.5689 28.62 33.37C28.62 33.1711 28.541 32.9803 28.4003 32.8397C28.2597 32.699 28.0689 32.62 27.87 32.62Z"
                fill="var(--svg-gray1-gray3)"
            />
        </svg>
    );
};
