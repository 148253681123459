import { ReactElement } from 'react';

export const PirateCruise = (): ReactElement => {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M25.4028 24.7782C24.1755 24.7782 23.1806 25.7731 23.1806 27.0004V80.0004C23.1806 80.43 22.8323 80.7782 22.4028 80.7782C21.9732 80.7782 21.625 80.43 21.625 80.0004V27.0004C21.625 24.914 23.3164 23.2227 25.4028 23.2227C27.4892 23.2227 29.1806 24.914 29.1806 27.0004V80.0004C29.1806 80.43 28.8323 80.7782 28.4028 80.7782C27.9732 80.7782 27.625 80.43 27.625 80.0004V27.0004C27.625 25.7731 26.6301 24.7782 25.4028 24.7782Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.6258 26.7782C36.8131 26.7782 31.0776 27.1587 28.5763 27.7307C28.1576 27.8265 27.7405 27.5647 27.6447 27.1459C27.549 26.7272 27.8108 26.3101 28.2295 26.2143C30.9083 25.6017 36.7879 25.2227 40.6258 25.2227C42.9256 25.2227 47.1702 25.9596 51.5897 26.7269C51.9309 26.7861 52.273 26.8455 52.6154 26.9048C57.4687 27.7448 62.405 28.5641 65.4604 28.5641C68.6562 28.5641 72.7288 27.9044 75.9957 27.2199C78.6024 26.6737 81.1807 28.6181 81.1807 31.3395V65.5265C81.1807 67.2335 80.1161 68.8197 78.4002 69.2889C75.2798 70.1424 70.3178 71.0673 65.4013 70.6925C61.5702 70.4004 58.4892 69.7509 55.4496 69.11C54.1801 68.8424 52.9179 68.5763 51.6113 68.3384C47.1554 67.5271 41.996 66.9962 33.852 67.7299C33.4242 67.7684 33.0462 67.4529 33.0076 67.025C32.9691 66.5972 33.2846 66.2192 33.7125 66.1806C42.0053 65.4335 47.3057 65.9733 51.89 66.808C53.2756 67.0603 54.5791 67.3353 55.8694 67.6075C58.8675 68.2399 61.795 68.8575 65.5195 69.1414C70.1948 69.4978 74.9669 68.6153 77.9898 67.7885C78.9614 67.5228 79.6252 66.6056 79.6252 65.5265V31.3395C79.6252 29.6632 78.0122 28.3867 76.3147 28.7424C73.0211 29.4325 68.8213 30.1196 65.4604 30.1196C62.24 30.1196 57.1498 29.2682 52.3501 28.4375C52.0396 28.3838 51.7304 28.3301 51.423 28.2768C46.9054 27.4932 42.7833 26.7782 40.6258 26.7782Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M53.7903 38.4247C49.0633 38.4247 45.2313 42.2567 45.2313 46.9836V47.7488C45.2313 50.7084 46.9951 53.3833 49.7154 54.5491C50.1557 54.7378 50.4783 55.1269 50.5823 55.5945L51.0074 57.5078C51.0753 57.8133 51.3463 58.0307 51.6594 58.0307H56.8978C57.2108 58.0307 57.4818 57.8133 57.5497 57.5078L57.9799 55.5719C58.0792 55.1251 58.3815 54.7502 58.7971 54.5584C61.3275 53.3905 62.9479 50.8579 62.9479 48.0709V47.095C62.9479 42.3065 59.0661 38.4247 54.2776 38.4247H53.7903ZM43.6758 46.9836C43.6758 41.3976 48.2042 36.8691 53.7903 36.8691H54.2776C59.9252 36.8691 64.5035 41.4474 64.5035 47.095V48.0709C64.5035 51.4499 62.5473 54.5219 59.4889 55.9522L59.0683 57.8452C58.8422 58.8625 57.9399 59.5863 56.8978 59.5863H51.6594C50.6173 59.5863 49.715 58.8625 49.4889 57.8452L49.0712 55.9653C45.7968 54.5473 43.6758 51.3193 43.6758 47.7488V46.9836Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.3559 46.2672C48.6055 45.9176 49.0913 45.8367 49.4408 46.0863L52.086 47.9758C52.4356 48.2254 52.5166 48.7112 52.2669 49.0607C52.0172 49.4103 51.5314 49.4913 51.1819 49.2416L48.5367 47.3521C48.1872 47.1025 48.1062 46.6167 48.3559 46.2672Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M60.2027 46.2672C59.953 45.9176 59.4673 45.8367 59.1177 46.0863L56.4725 47.9758C56.123 48.2254 56.042 48.7112 56.2917 49.0607C56.5414 49.4103 57.0271 49.4913 57.3767 49.2416L60.0219 47.3521C60.3714 47.1025 60.4524 46.6167 60.2027 46.2672Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M71.3911 38.5444C71.6059 38.9164 71.4784 39.3921 71.1064 39.6069L66.3918 42.3289C66.0197 42.5436 65.5441 42.4162 65.3293 42.0442C65.1145 41.6722 65.242 41.1965 65.614 40.9817L70.3286 38.2597C70.7006 38.0449 71.1763 38.1724 71.3911 38.5444Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.7486 54.5013C43.9634 54.8733 43.8359 55.3489 43.4639 55.5637L37.1419 59.2137C36.7699 59.4285 36.2942 59.3011 36.0795 58.929C35.8647 58.557 35.9921 58.0814 36.3641 57.8666L42.6862 54.2166C43.0582 54.0018 43.5338 54.1292 43.7486 54.5013Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.3535 41.5872C72.9814 41.802 72.5058 41.6746 72.291 41.3026L69.3539 36.2154C69.1391 35.8434 69.2666 35.3677 69.6386 35.1529C70.0106 34.9381 70.4863 35.0656 70.7011 35.4376L73.6381 40.5248C73.8529 40.8968 73.7255 41.3725 73.3535 41.5872Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M73.3535 55.4284C72.9814 55.2136 72.5058 55.3411 72.291 55.7131L69.3539 60.8002C69.1391 61.1723 69.2666 61.6479 69.6386 61.8627C70.0106 62.0775 70.4863 61.95 70.7011 61.578L73.6381 56.4909C73.8529 56.1188 73.7255 55.6432 73.3535 55.4284Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.627 41.5872C34.999 41.802 35.4747 41.6746 35.6895 41.3026L38.6266 36.2154C38.8413 35.8434 38.7139 35.3677 38.3419 35.1529C37.9699 34.9381 37.4942 35.0656 37.2794 35.4376L34.3423 40.5248C34.1276 40.8968 34.255 41.3725 34.627 41.5872Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M34.627 55.4284C34.999 55.2136 35.4747 55.3411 35.6895 55.7131L38.6266 60.8002C38.8413 61.1723 38.7139 61.6479 38.3419 61.8627C37.9699 62.0775 37.4942 61.95 37.2794 61.578L34.3423 56.4909C34.1276 56.1188 34.255 55.6432 34.627 55.4284Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64.4724 54.6363C64.2576 55.0083 64.385 55.484 64.757 55.6988L70.1748 58.8267C70.5468 59.0415 71.0225 58.9141 71.2373 58.5421C71.452 58.17 71.3246 57.6944 70.9526 57.4796L65.5348 54.3516C65.1628 54.1369 64.6871 54.2643 64.4724 54.6363Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.9123 38.1469C35.6975 38.5189 35.8249 38.9946 36.1969 39.2094L41.369 42.1954C41.741 42.4102 42.2166 42.2827 42.4314 41.9107C42.6462 41.5387 42.5187 41.0631 42.1467 40.8483L36.9747 37.8622C36.6027 37.6474 36.127 37.7749 35.9123 38.1469Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
