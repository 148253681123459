import { TFaqItem } from '@/common/service/api/FAQ/FAQ.domain';

export const createFAQStructuredData = (questions: TFaqItem[]): string => {
    const faqArray = questions.map((item) => {
        return {
            '@type': 'Question',
            'name': item.question,
            'acceptedAnswer': {
                '@type': 'Answer',
                'text': `<p>${item.answer}</p>`,
            },
        };
    });

    const templateScript = {
        '@context': 'https://schema.org/',
        '@type': 'FAQPage',
        'mainEntity': faqArray,
    };

    return JSON.stringify(templateScript);
};
