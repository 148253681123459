import { ReactElement } from 'react';

export const SunsetCruises = (): ReactElement => {
    return (
        <svg
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M33.5 67C52.0015 67 67 52.0015 67 33.5C67 14.9985 52.0015 0 33.5 0C14.9985 0 0 14.9985 0 33.5C0 52.0015 14.9985 67 33.5 67Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M52.0925 38.86H15.2425C15.1092 38.86 14.9814 38.807 14.8872 38.7128C14.7929 38.6186 14.74 38.4908 14.74 38.3575C14.74 38.2242 14.7929 38.0964 14.8872 38.0022C14.9814 37.9079 15.1092 37.855 15.2425 37.855H52.0925C52.2258 37.855 52.3536 37.9079 52.4478 38.0022C52.5421 38.0964 52.595 38.2242 52.595 38.3575C52.595 38.4908 52.5421 38.6186 52.4478 38.7128C52.3536 38.807 52.2258 38.86 52.0925 38.86Z"
                fill="#0DD1C5"
            />
            <path
                d="M46.1231 42.4445H21.1522C21.0189 42.4445 20.8911 42.3915 20.7968 42.2973C20.7026 42.203 20.6497 42.0752 20.6497 41.942C20.6497 41.8087 20.7026 41.6809 20.7968 41.5866C20.8911 41.4924 21.0189 41.4395 21.1522 41.4395H46.1231C46.2563 41.4395 46.3841 41.4924 46.4784 41.5866C46.5726 41.6809 46.6256 41.8087 46.6256 41.942C46.6256 42.0752 46.5726 42.203 46.4784 42.2973C46.3841 42.3915 46.2563 42.4445 46.1231 42.4445V42.4445Z"
                fill="#0DD1C5"
            />
            <path
                d="M41.2858 45.9952H25.9428C25.8095 45.9952 25.6817 45.9423 25.5875 45.8481C25.4932 45.7538 25.4403 45.626 25.4403 45.4927C25.4403 45.3595 25.4932 45.2317 25.5875 45.1374C25.6817 45.0432 25.8095 44.9902 25.9428 44.9902H41.2858C41.4191 44.9902 41.5469 45.0432 41.6411 45.1374C41.7354 45.2317 41.7883 45.3595 41.7883 45.4927C41.7883 45.626 41.7354 45.7538 41.6411 45.8481C41.5469 45.9423 41.4191 45.9952 41.2858 45.9952V45.9952Z"
                fill="#0DD1C5"
            />
            <path
                d="M21.3061 31.8987C21.2484 31.908 21.1896 31.908 21.1319 31.8987L16.596 30.2237C16.5307 30.2015 16.4708 30.1661 16.4198 30.1198C16.3688 30.0734 16.3279 30.0171 16.2996 29.9542C16.2714 29.8914 16.2563 29.8234 16.2554 29.7545C16.2545 29.6856 16.2678 29.6173 16.2945 29.5537C16.3175 29.4891 16.3533 29.4298 16.3998 29.3793C16.4464 29.3289 16.5026 29.2883 16.5652 29.2602C16.6278 29.232 16.6954 29.2168 16.764 29.2154C16.8326 29.2141 16.9008 29.2266 16.9645 29.2522L21.5004 30.9272C21.5656 30.9494 21.6256 30.9848 21.6765 31.0312C21.7275 31.0775 21.7684 31.1339 21.7967 31.1967C21.825 31.2596 21.84 31.3275 21.8409 31.3964C21.8418 31.4653 21.8285 31.5337 21.8019 31.5972C21.7614 31.6932 21.6917 31.774 21.6027 31.8281C21.5137 31.8822 21.4099 31.9069 21.3061 31.8987V31.8987Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M26.1301 25.6407C26.0537 25.6431 25.9778 25.628 25.9082 25.5967C25.8385 25.5653 25.7769 25.5185 25.7281 25.4598L22.961 21.4398C22.8846 21.3305 22.8547 21.1954 22.8779 21.0641C22.9012 20.9328 22.9756 20.8161 23.0849 20.7397C23.1942 20.6632 23.3294 20.6334 23.4607 20.6566C23.592 20.6799 23.7087 20.7543 23.7851 20.8636L26.5522 24.8367C26.6214 24.9427 26.6475 25.0711 26.625 25.1957C26.6026 25.3202 26.5334 25.4315 26.4316 25.5067C26.3487 25.5836 26.2427 25.6307 26.1301 25.6407V25.6407Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M34.1896 17.9224C34.1896 17.6449 33.9646 17.4199 33.6871 17.4199C33.4095 17.4199 33.1846 17.6449 33.1846 17.9224V22.7598C33.1846 23.0373 33.4095 23.2623 33.6871 23.2623C33.9646 23.2623 34.1896 23.0373 34.1896 22.7598V17.9224Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M41.2386 25.634C41.1353 25.6327 41.0348 25.6 40.9505 25.5402C40.8489 25.465 40.7794 25.3543 40.7558 25.2301C40.7322 25.106 40.7563 24.9775 40.8232 24.8702L43.5903 20.8971C43.6664 20.7968 43.7771 20.7283 43.9008 20.7048C44.0246 20.6812 44.1526 20.7044 44.2603 20.7698C44.3608 20.846 44.429 20.9572 44.4513 21.0813C44.4737 21.2054 44.4485 21.3334 44.3809 21.4398L41.6138 25.4129C41.573 25.4766 41.5177 25.5298 41.4526 25.5682C41.3874 25.6066 41.3141 25.6292 41.2386 25.634V25.634Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M46.0752 31.8918C45.9551 31.8949 45.8378 31.8548 45.7447 31.7788C45.6516 31.7028 45.5888 31.5959 45.5677 31.4776C45.5466 31.3593 45.5687 31.2373 45.6298 31.1338C45.691 31.0304 45.7872 30.9522 45.901 30.9136L50.4436 29.2386C50.5689 29.1924 50.7074 29.1979 50.8286 29.2538C50.9499 29.3097 51.044 29.4115 51.0902 29.5368C51.1364 29.662 51.1309 29.8005 51.075 29.9218C51.0191 30.043 50.9173 30.1371 50.792 30.1833L46.2293 31.8583C46.18 31.8775 46.128 31.8888 46.0752 31.8918V31.8918Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.5 25.9625C27.857 25.9625 23.2825 30.537 23.2825 36.18C23.2825 36.4575 23.0575 36.6825 22.78 36.6825C22.5024 36.6825 22.2775 36.4575 22.2775 36.18C22.2775 29.982 27.3019 24.9575 33.5 24.9575C39.698 24.9575 44.7225 29.982 44.7225 36.18C44.7225 36.4575 44.4975 36.6825 44.22 36.6825C43.9424 36.6825 43.7175 36.4575 43.7175 36.18C43.7175 30.537 39.1429 25.9625 33.5 25.9625Z"
                fill="var(--svg-gray1-gray3)"
            />
        </svg>
    );
};
