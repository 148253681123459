import { TFaqItem } from '@/common/service/api/FAQ/FAQ.domain';
import Head from 'next/head';
import { memo, ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { createFAQStructuredData } from '../service/Creators';
import * as S from './FAQ.styles';
import ReactMarkdown from 'react-markdown';

type FAQProps = {
    data?: TFaqItem[];
    children?: ReactNode;
};

export const FAQ = ({ data, children }: FAQProps): ReactElement => {
    const [isOpen, setIsOpen] = useState(-1);

    const openTab = useCallback(
        (newIndex: number) => () => {
            if (newIndex === isOpen) {
                setIsOpen(-1);
            } else {
                setIsOpen(newIndex);
            }
        },
        [isOpen]
    );

    const structuredData = useMemo(() => data && createFAQStructuredData(data), [data]);

    return (
        <S.CommonContainer>
            {!!data?.length && (
                <S.FAQWrappper>
                    {structuredData && (
                        <Head>
                            <script
                                type="application/ld+json"
                                dangerouslySetInnerHTML={{ __html: structuredData }}
                            />
                        </Head>
                    )}
                    <S.Title>Frequently Asked Questions</S.Title>
                    <>
                        {data.map(({ question, answer }, index) => {
                            const isActive = isOpen === index;

                            return (
                                <S.Item key={question}>
                                    <S.Question
                                        onClick={openTab(index)}
                                        data-test-id="FAQ-Question"
                                    >
                                        {`${++index}. ${question}`}
                                        <S.Arrow isActive={isActive} />
                                    </S.Question>
                                    <S.Animation isActive={isActive}>
                                        <S.Answer data-test-id="FAQ-Answer">
                                            <ReactMarkdown linkTarget="_blank">
                                                {answer}
                                            </ReactMarkdown>
                                        </S.Answer>
                                    </S.Animation>
                                </S.Item>
                            );
                        })}
                    </>
                </S.FAQWrappper>
            )}
            {!!children && children}
        </S.CommonContainer>
    );
};

export default memo(FAQ);
