import { ReactElement } from 'react';

export const Waterparks = (): ReactElement => {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.4461 27.0574C32.4461 25.2917 33.8775 23.8604 35.6432 23.8604H39.2174C44.1746 23.8604 48.2589 25.3561 51.5764 28.016C54.8821 30.6664 57.3871 34.4404 59.26 38.9436L61.2125 43.6385C63.2173 48.459 66.1097 51.3169 70.1559 51.3169H76.9999C78.5187 51.3169 79.7499 52.5482 79.7499 54.0669V60.0745C79.7499 61.5933 78.5187 62.8245 76.9999 62.8245H70.1559C64.2434 62.8245 60.1389 61.481 57.0381 58.9184C53.9618 56.376 51.9642 52.7047 50.1134 48.2545L48.1608 43.5596C47.1431 41.1125 46.1171 39.2008 44.7504 37.8953C43.4179 36.6224 41.7129 35.8811 39.2174 35.8811H25.3595C24.1006 35.8811 23.0801 34.8606 23.0801 33.6017V31.4001C23.0801 30.1413 24.1006 29.1207 25.3595 29.1207H31.6667C32.0972 29.1207 32.4461 28.7718 32.4461 28.3413V27.0574ZM35.6432 25.3604C34.7059 25.3604 33.9461 26.1202 33.9461 27.0574V28.3413C33.9461 29.6002 32.9256 30.6207 31.6667 30.6207H25.3595C24.929 30.6207 24.5801 30.9697 24.5801 31.4001V33.6017C24.5801 34.0321 24.929 34.3811 25.3595 34.3811H39.2174C42.0601 34.3811 44.1481 35.2456 45.7865 36.8107C47.3908 38.3432 48.5137 40.5018 49.5458 42.9836L51.4984 47.6785C53.3293 52.081 55.2125 55.4637 57.9937 57.7622C60.7503 60.0403 64.48 61.3245 70.1559 61.3245H76.9999C77.6902 61.3245 78.2499 60.7648 78.2499 60.0745V54.0669C78.2499 53.3766 77.6902 52.8169 76.9999 52.8169H70.1559C65.2032 52.8169 61.9224 49.2516 59.8275 44.2145L57.875 39.5196C56.066 35.1699 53.69 31.6333 50.6381 29.1863C47.598 26.7488 43.8482 25.3604 39.2174 25.3604H35.6432Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.3535 44.5869C27.3535 44.1727 27.6893 43.8369 28.1035 43.8369H39.0091C39.4233 43.8369 39.7591 44.1727 39.7591 44.5869C39.7591 45.0011 39.4233 45.3369 39.0091 45.3369H28.1035C27.6893 45.3369 27.3535 45.0011 27.3535 44.5869Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.3535 53.8145C27.3535 53.4002 27.6893 53.0645 28.1035 53.0645H39.0091C39.4233 53.0645 39.7591 53.4002 39.7591 53.8145C39.7591 54.2287 39.4233 54.5645 39.0091 54.5645H28.1035C27.6893 54.5645 27.3535 54.2287 27.3535 53.8145Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.1035 34.6084C28.5177 34.6084 28.8535 34.9442 28.8535 35.3584L28.8535 62.2029C28.8535 62.6172 28.5177 62.9529 28.1035 62.9529C27.6893 62.9529 27.3535 62.6172 27.3535 62.2029L27.3535 35.3584C27.3535 34.9442 27.6893 34.6084 28.1035 34.6084Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.0098 34.6084C39.424 34.6084 39.7598 34.9442 39.7598 35.3584L39.7598 62.2029C39.7598 62.6172 39.424 62.9529 39.0098 62.9529C38.5956 62.9529 38.2598 62.6172 38.2598 62.2029L38.2598 35.3584C38.2598 34.9442 38.5956 34.6084 39.0098 34.6084Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M27.0012 73.9314C25.407 73.9321 23.8349 73.5588 22.4112 72.8414C22.3235 72.7979 22.2452 72.7374 22.181 72.6634C22.1168 72.5894 22.068 72.5034 22.0373 72.4103C22.0066 72.3173 21.9947 72.2191 22.0022 72.1215C22.0097 72.0238 22.0366 71.9286 22.0812 71.8414C22.1694 71.6643 22.3239 71.5292 22.5112 71.4655C22.6985 71.4018 22.9034 71.4147 23.0812 71.5014C24.2143 72.0712 25.4581 72.387 26.7258 72.4267C27.9935 72.4665 29.2546 72.2292 30.4212 71.7314L32.5812 70.8114C33.9182 70.2455 35.3601 69.9699 36.8116 70.0026C38.2631 70.0354 39.6911 70.3758 41.0012 71.0014L42.1612 71.5514C43.3257 72.1024 44.5979 72.3881 45.8862 72.3881C47.1745 72.3881 48.4467 72.1024 49.6112 71.5514L50.8212 70.9914C52.1761 70.3616 53.6521 70.0353 55.1462 70.0353C56.6403 70.0353 58.1164 70.3616 59.4712 70.9914L60.4712 71.4914C61.6794 72.0553 63.0006 72.3349 64.3336 72.3089C65.6666 72.2829 66.976 71.952 68.1612 71.3414C69.5976 70.6186 71.1832 70.2421 72.7912 70.2421C74.3992 70.2421 75.9849 70.6186 77.4212 71.3414C77.5966 71.4313 77.7295 71.5866 77.7912 71.7737C77.853 71.9609 77.8386 72.1648 77.7512 72.3414C77.7077 72.4292 77.6472 72.5074 77.5732 72.5716C77.4992 72.6358 77.4132 72.6846 77.3202 72.7153C77.2271 72.746 77.129 72.758 77.0313 72.7504C76.9336 72.7429 76.8384 72.716 76.7512 72.6714C75.5247 72.0526 74.17 71.7302 72.7962 71.7302C71.4224 71.7302 70.0678 72.0526 68.8412 72.6714C67.454 73.3863 65.9213 73.7734 64.361 73.8029C62.8007 73.8324 61.2544 73.5034 59.8412 72.8414L58.7812 72.3414C57.6255 71.8031 56.3661 71.5242 55.0912 71.5242C53.8163 71.5242 52.5569 71.8031 51.4012 72.3414L50.1912 72.9114C48.8264 73.5561 47.3357 73.8904 45.8262 73.8904C44.3168 73.8904 42.8261 73.5561 41.4612 72.9114L40.3012 72.3614C39.1835 71.8287 37.9653 71.5398 36.7274 71.5139C35.4894 71.488 34.2602 71.7258 33.1212 72.2114L30.9612 73.1214C29.7085 73.6529 28.362 73.9283 27.0012 73.9314Z"
                fill="#0DD1C5"
            />
            <path
                d="M45.7558 79.8912C44.2294 79.8927 42.7222 79.5509 41.3458 78.8912L40.1858 78.3412C39.0664 77.8082 37.8465 77.5192 36.6069 77.4934C35.3674 77.4675 34.1365 77.7054 32.9958 78.1912C32.8157 78.2537 32.6186 78.2452 32.4445 78.1676C32.2704 78.0899 32.1324 77.9489 32.0586 77.7732C31.9847 77.5975 31.9806 77.4002 32.0469 77.2215C32.1133 77.0428 32.2451 76.8961 32.4158 76.8112C33.7515 76.2412 35.1933 75.9625 36.6452 75.9935C38.0971 76.0246 39.5257 76.3647 40.8358 76.9912L41.9858 77.5412C43.1522 78.0916 44.426 78.3771 45.7158 78.3771C47.0056 78.3771 48.2794 78.0916 49.4458 77.5412L50.6558 76.9712C52.0089 76.3416 53.4833 76.0153 54.9758 76.0153C56.4682 76.0153 57.9427 76.3416 59.2958 76.9712L60.3558 77.4612C61.5616 78.0271 62.8815 78.308 64.2133 78.282C65.5451 78.256 66.853 77.9238 68.0358 77.3112C68.2128 77.2264 68.4158 77.2134 68.6022 77.2749C68.7886 77.3364 68.944 77.4677 69.0358 77.6412C69.1239 77.8166 69.1398 78.0196 69.08 78.2065C69.0201 78.3935 68.8894 78.5495 68.7158 78.6412C67.3286 79.3561 65.7959 79.7432 64.2356 79.7727C62.6753 79.8022 61.129 79.4732 59.7158 78.8112L58.6558 78.3212C57.5009 77.7803 56.2411 77.4999 54.9658 77.4999C53.6905 77.4999 52.4307 77.7803 51.2758 78.3212L50.0658 78.8912C48.7192 79.5346 47.2482 79.8759 45.7558 79.8912Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
