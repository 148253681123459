import { ReactElement } from 'react';

export const DayTripsAndExcursions = (): ReactElement => {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50 100C59.889 100 69.556 97.0676 77.7785 91.5735C86.0009 86.0794 92.4096 78.2705 96.1939 69.1342C99.9783 59.9979 100.969 49.9445 99.0392 40.2455C97.11 30.5464 92.3479 21.6373 85.3553 14.6447C78.3627 7.65206 69.4535 2.89002 59.7545 0.960758C50.0555 -0.968502 40.0021 0.0216641 30.8658 3.80604C21.7295 7.59043 13.9206 13.9991 8.42651 22.2215C2.93244 30.444 0 40.1109 0 50C0 63.2608 5.26783 75.9785 14.6446 85.3553C24.0215 94.7322 36.7392 100 50 100Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M22.8927 70.4589C20.9968 70.4581 19.1272 70.0152 17.4324 69.1654C17.2465 69.07 17.1055 68.9054 17.0401 68.707C16.9746 68.5086 16.9898 68.2924 17.0825 68.1051C17.1286 68.0121 17.1928 67.9291 17.2712 67.861C17.3497 67.793 17.4409 67.7412 17.5395 67.7086C17.6381 67.6761 17.7422 67.6634 17.8458 67.6714C17.9493 67.6794 18.0503 67.7079 18.1428 67.7552C19.5169 68.4431 21.0242 68.824 22.5602 68.8716C24.0962 68.9192 25.6242 68.6323 27.0384 68.0309L29.636 66.9706C31.2262 66.2961 32.9414 65.9662 34.6685 66.0027C36.3955 66.0393 38.0952 66.4414 39.6555 67.1827L41.0444 67.8506C42.4572 68.5159 43.9995 68.8608 45.5611 68.8608C47.1227 68.8608 48.6651 68.5159 50.0779 67.8506L51.5304 67.1721C53.1424 66.4205 54.8994 66.0311 56.678 66.0311C58.4565 66.0311 60.2136 66.4205 61.8256 67.1721L63.0873 67.7658C64.5518 68.4478 66.1528 68.786 67.768 68.7548C69.3832 68.7236 70.9699 68.3236 72.407 67.5856C74.1156 66.7251 76.002 66.2769 77.915 66.2769C79.8281 66.2769 81.7145 66.7251 83.4231 67.5856C83.6089 67.6811 83.7504 67.845 83.8176 68.0428C83.8849 68.2407 83.8727 68.4568 83.7836 68.6458C83.6882 68.8317 83.5236 68.9727 83.3252 69.0381C83.1268 69.1036 82.9106 69.0884 82.7233 68.9957C81.2434 68.25 79.6093 67.8615 77.9521 67.8615C76.295 67.8615 74.6609 68.25 73.181 68.9957C71.5293 69.8429 69.7059 70.3015 67.85 70.3364C65.994 70.3713 64.1546 69.9817 62.4723 69.1972L61.2 68.6034C59.7986 67.9511 58.2715 67.6131 56.7257 67.6131C55.1799 67.6131 53.6528 67.9511 52.2514 68.6034L50.7988 69.282C49.1703 70.0405 47.3941 70.4291 45.5976 70.4199C43.8011 70.4108 42.0289 70.0041 40.4082 69.229L39.0193 68.5716C37.6631 67.9239 36.1847 67.5719 34.6822 67.539C33.1796 67.5061 31.6872 67.7931 30.3039 68.3808L27.7169 69.441C26.1946 70.1041 24.5532 70.4505 22.8927 70.4589Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M84.25 41.1035L83.7875 41.2288C73.6837 43.9652 63.262 45.3515 52.7942 45.3515H18.75V49.1574H53.3771C63.3312 49.1574 73.2447 47.8878 82.8775 45.3792L84.25 45.0218V41.1035ZM83.9349 39.6348C84.8497 39.3871 85.75 40.076 85.75 41.0237V45.069C85.75 45.7241 85.3076 46.2964 84.6737 46.4615L83.2555 46.8308C73.4992 49.3715 63.4588 50.6574 53.3771 50.6574H18.6889C17.8942 50.6574 17.25 50.0132 17.25 49.2185V45.2905C17.25 44.4958 17.8942 43.8515 18.6889 43.8515H52.7942C63.1296 43.8515 73.4194 42.4828 83.3954 39.7809L83.9349 39.6348Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M81.7842 45.8061C82.1676 45.9629 82.3512 46.4008 82.1943 46.7842L79.5378 53.277C77.8335 57.4425 73.7797 60.1639 69.2791 60.1639H25.5043C24.0922 60.1639 22.8514 59.2274 22.4642 57.8694L20.2789 50.2059C20.1654 49.8075 20.3962 49.3925 20.7945 49.2789C21.1929 49.1654 21.6079 49.3962 21.7214 49.7945L23.9067 57.4581C24.1101 58.1718 24.7622 58.6639 25.5043 58.6639H69.2791C73.1706 58.6639 76.6759 56.3108 78.1495 52.709L80.806 46.2162C80.9629 45.8328 81.4008 45.6492 81.7842 45.8061Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.1322 27.25C30.644 27.25 29.4375 28.4565 29.4375 29.9447C29.4375 31.433 30.644 32.6395 32.1322 32.6395C33.6205 32.6395 34.827 31.433 34.827 29.9447C34.827 28.4565 33.6205 27.25 32.1322 27.25ZM27.9375 29.9447C27.9375 27.628 29.8155 25.75 32.1322 25.75C34.4489 25.75 36.327 27.628 36.327 29.9447C36.327 32.2614 34.4489 34.1395 32.1322 34.1395C29.8155 34.1395 27.9375 32.2614 27.9375 29.9447Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32 36.75C30.2051 36.75 28.75 38.2051 28.75 40V42C28.75 42.4142 28.4142 42.75 28 42.75C27.5858 42.75 27.25 42.4142 27.25 42V40C27.25 37.3766 29.3766 35.25 32 35.25C34.6234 35.25 36.75 37.3766 36.75 40V42C36.75 42.4142 36.4142 42.75 36 42.75C35.5858 42.75 35.25 42.4142 35.25 42V40C35.25 38.2051 33.7949 36.75 32 36.75Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.1439 27.25C46.6557 27.25 45.4492 28.4565 45.4492 29.9447C45.4492 31.433 46.6557 32.6395 48.1439 32.6395C49.6322 32.6395 50.8387 31.433 50.8387 29.9447C50.8387 28.4565 49.6322 27.25 48.1439 27.25ZM43.9492 29.9447C43.9492 27.628 45.8273 25.75 48.1439 25.75C50.4606 25.75 52.3387 27.628 52.3387 29.9447C52.3387 32.2614 50.4606 34.1395 48.1439 34.1395C45.8273 34.1395 43.9492 32.2614 43.9492 29.9447Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48 36.75C46.2051 36.75 44.75 38.2051 44.75 40V42C44.75 42.4142 44.4142 42.75 44 42.75C43.5858 42.75 43.25 42.4142 43.25 42V40C43.25 37.3766 45.3766 35.25 48 35.25C50.6234 35.25 52.75 37.3766 52.75 40V42C52.75 42.4142 52.4142 42.75 52 42.75C51.5858 42.75 51.25 42.4142 51.25 42V40C51.25 38.2051 49.7949 36.75 48 36.75Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.2885 27.25C61.8002 27.25 60.5938 28.4565 60.5938 29.9447C60.5938 31.433 61.8002 32.6395 63.2885 32.6395C64.7767 32.6395 65.9832 31.433 65.9832 29.9447C65.9832 28.4565 64.7767 27.25 63.2885 27.25ZM59.0938 29.9447C59.0938 27.628 60.9718 25.75 63.2885 25.75C65.6052 25.75 67.4832 27.628 67.4832 29.9447C67.4832 32.2614 65.6052 34.1395 63.2885 34.1395C60.9718 34.1395 59.0938 32.2614 59.0938 29.9447Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63 36.75C61.2051 36.75 59.75 38.2051 59.75 40V42C59.75 42.4142 59.4142 42.75 59 42.75C58.5858 42.75 58.25 42.4142 58.25 42V40C58.25 37.3766 60.3766 35.25 63 35.25C65.6234 35.25 67.75 37.3766 67.75 40V41C67.75 41.4142 67.4142 41.75 67 41.75C66.5858 41.75 66.25 41.4142 66.25 41V40C66.25 38.2051 64.7949 36.75 63 36.75Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M45.5317 78.4785C43.7155 78.4795 41.9222 78.0738 40.2834 77.291L38.8839 76.623C37.5204 75.9725 36.0337 75.6208 34.5233 75.5915C33.0129 75.5623 31.5137 75.8561 30.1261 76.4534C30.0288 76.4969 29.9239 76.5204 29.8174 76.5225C29.7109 76.5246 29.6051 76.5053 29.5062 76.4658C29.4073 76.4262 29.3174 76.3672 29.2417 76.2922C29.1661 76.2172 29.1062 76.1278 29.0658 76.0293C29.0238 75.9319 29.0015 75.8271 29.0001 75.721C28.9987 75.6149 29.0182 75.5096 29.0576 75.4111C29.097 75.3126 29.1555 75.2228 29.2297 75.1469C29.3039 75.0711 29.3923 75.0106 29.4899 74.969C31.0822 74.2939 32.7998 73.9646 34.5289 74.003C36.258 74.0414 37.9592 74.4466 39.52 75.1917L40.909 75.849C42.3232 76.5159 43.8674 76.8617 45.431 76.8617C46.9945 76.8617 48.5388 76.5159 49.953 75.849L51.4161 75.1705C53.0273 74.4161 54.7847 74.0251 56.5637 74.0251C58.3428 74.0251 60.1001 74.4161 61.7113 75.1705L62.9836 75.7642C64.4495 76.4479 66.0524 76.7871 67.6696 76.7558C69.2869 76.7246 70.8755 76.3237 72.3139 75.584C72.5016 75.4941 72.7168 75.4803 72.9145 75.5455C73.1121 75.6108 73.2769 75.7499 73.3742 75.9339C73.4668 76.1211 73.4821 76.3373 73.4166 76.5357C73.3511 76.7341 73.2102 76.8988 73.0243 76.9941C71.3725 77.8469 69.5467 78.3085 67.6881 78.3435C65.8295 78.3784 63.9877 77.9857 62.305 77.1956L61.0327 76.6018C59.6299 75.9479 58.1009 75.609 56.5531 75.609C55.0053 75.609 53.4764 75.9479 52.0735 76.6018L50.6104 77.2804C49.023 78.0394 47.2909 78.448 45.5317 78.4785Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
