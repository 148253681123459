import { ReactElement } from 'react';

export const SegwayTours = (): ReactElement => {
    return (
        <svg
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M33.5 67C52.0015 67 67 52.0015 67 33.5C67 14.9985 52.0015 0 33.5 0C14.9985 0 0 14.9985 0 33.5C0 52.0015 14.9985 67 33.5 67Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M38.2241 49.58C36.667 49.58 35.145 49.1182 33.8503 48.2532C32.5557 47.3882 31.5467 46.1586 30.9508 44.7201C30.355 43.2816 30.1991 41.6987 30.5028 40.1716C30.8066 38.6445 31.5564 37.2418 32.6574 36.1408C33.7584 35.0398 35.1611 34.29 36.6882 33.9862C38.2153 33.6825 39.7982 33.8384 41.2367 34.4342C42.6753 35.0301 43.9048 36.0391 44.7698 37.3337C45.6349 38.6284 46.0966 40.1504 46.0966 41.7075C46.0948 43.7948 45.2648 45.7962 43.7888 47.2722C42.3128 48.7482 40.3114 49.5782 38.2241 49.58ZM38.2241 34.84C36.8658 34.84 35.538 35.2427 34.4087 35.9973C33.2793 36.752 32.3991 37.8245 31.8793 39.0794C31.3595 40.3343 31.2235 41.7151 31.4885 43.0472C31.7535 44.3794 32.4076 45.6031 33.368 46.5635C34.3284 47.524 35.5521 48.178 36.8843 48.443C38.2164 48.708 39.5973 48.572 40.8521 48.0522C42.107 47.5324 43.1796 46.6522 43.9342 45.5228C44.6888 44.3935 45.0916 43.0657 45.0916 41.7075C45.0898 39.8866 44.3657 38.1409 43.0782 36.8534C41.7906 35.5658 40.0449 34.8417 38.2241 34.84Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M27.6509 42.2101C27.5177 42.2101 27.3899 42.1571 27.2956 42.0629C27.2014 41.9687 27.1484 41.8409 27.1484 41.7076C27.1495 38.8724 28.2371 36.1455 30.1875 34.0879C32.138 32.0303 34.8029 30.7984 37.6339 30.6459L34.7663 12.6631C34.7019 12.2429 34.4884 11.86 34.1648 11.5845C33.8413 11.3089 33.4293 11.159 33.0042 11.1623H30.15C30.0168 11.1623 29.889 11.1093 29.7947 11.0151C29.7005 10.9209 29.6475 10.7931 29.6475 10.6598C29.6475 10.5265 29.7005 10.3987 29.7947 10.3045C29.889 10.2102 30.0168 10.1573 30.15 10.1573H32.9908C33.6558 10.153 34.3001 10.3883 34.8058 10.8201C35.3115 11.252 35.6447 11.8515 35.7445 12.509L38.7059 31.0479C38.7162 31.1202 38.7113 31.1938 38.6917 31.2641C38.6721 31.3345 38.6382 31.4 38.592 31.4566C38.5453 31.5121 38.4868 31.5566 38.4207 31.5867C38.3547 31.6169 38.2827 31.6319 38.2101 31.6308C35.5474 31.6396 32.9963 32.7013 31.1135 34.5841C29.2306 36.4669 28.169 39.0181 28.1601 41.6808C28.1628 41.7493 28.1517 41.8177 28.1273 41.8818C28.1029 41.9459 28.0658 42.0045 28.0183 42.0539C27.9707 42.1033 27.9137 42.1427 27.8506 42.1695C27.7874 42.1963 27.7195 42.2101 27.6509 42.2101Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M38.223 44.8896C37.589 44.8909 36.9689 44.704 36.4412 44.3526C35.9135 44.0012 35.502 43.5011 35.2587 42.9156C35.0155 42.3302 34.9515 41.6857 35.0749 41.0638C35.1982 40.442 35.5034 39.8707 35.9517 39.4224C36.4 38.9741 36.9713 38.6689 37.5931 38.5456C38.215 38.4222 38.8595 38.4862 39.4449 38.7294C40.0304 38.9727 40.5305 39.3842 40.8819 39.9119C41.2333 40.4396 41.4202 41.0597 41.4189 41.6937C41.4171 42.5407 41.0798 43.3526 40.4809 43.9516C39.8819 44.5505 39.07 44.8878 38.223 44.8896ZM38.223 39.5296C37.788 39.5282 37.3625 39.656 37.0002 39.8967C36.638 40.1373 36.3552 40.4801 36.1879 40.8815C36.0205 41.283 35.976 41.725 36.0601 42.1518C36.1441 42.5785 36.3529 42.9707 36.66 43.2787C36.967 43.5867 37.3586 43.7967 37.7851 43.882C38.2115 43.9674 38.6537 43.9243 39.0557 43.7581C39.4576 43.592 39.8013 43.3103 40.043 42.9488C40.2848 42.5872 40.4139 42.1621 40.4139 41.7272C40.4147 41.4389 40.3587 41.1533 40.249 40.8867C40.1393 40.6201 39.9781 40.3778 39.7745 40.1736C39.571 39.9695 39.3292 39.8075 39.0629 39.697C38.7967 39.5865 38.5112 39.5296 38.223 39.5296Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M43.0404 53.4591H24.7896C24.6563 53.4591 24.5285 53.4062 24.4343 53.3119C24.3401 53.2177 24.2871 53.0899 24.2871 52.9566C24.2871 52.8233 24.3401 52.6955 24.4343 52.6013C24.5285 52.507 24.6563 52.4541 24.7896 52.4541H43.0404C43.1737 52.4541 43.3015 52.507 43.3957 52.6013C43.49 52.6955 43.5429 52.8233 43.5429 52.9566C43.5429 53.0899 43.49 53.2177 43.3957 53.3119C43.3015 53.4062 43.1737 53.4591 43.0404 53.4591Z"
                fill="#0DD1C5"
            />
            <path
                d="M29.9282 50.1632H19.0943C18.961 50.1632 18.8332 50.1103 18.739 50.016C18.6447 49.9218 18.5918 49.794 18.5918 49.6607C18.5918 49.5274 18.6447 49.3996 18.739 49.3054C18.8332 49.2111 18.961 49.1582 19.0943 49.1582H29.9282C30.0615 49.1582 30.1893 49.2111 30.2835 49.3054C30.3778 49.3996 30.4307 49.5274 30.4307 49.6607C30.4307 49.794 30.3778 49.9218 30.2835 50.016C30.1893 50.1103 30.0615 50.1632 29.9282 50.1632Z"
                fill="#0DD1C5"
            />
            <path
                d="M26.5049 46.8995H22.4849C22.3517 46.8995 22.2238 46.8466 22.1296 46.7524C22.0354 46.6581 21.9824 46.5303 21.9824 46.397C21.9824 46.2638 22.0354 46.1359 22.1296 46.0417C22.2238 45.9475 22.3517 45.8945 22.4849 45.8945H26.5049C26.6382 45.8945 26.766 45.9475 26.8602 46.0417C26.9545 46.1359 27.0074 46.2638 27.0074 46.397C27.0074 46.5303 26.9545 46.6581 26.8602 46.7524C26.766 46.8466 26.6382 46.8995 26.5049 46.8995Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
