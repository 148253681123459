import styled from 'styled-components';
import Text from 'src/styles/Themes/Text';
import Screen from 'src/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import { LeftArrow, RightArrow } from '@/common/ui';
import Palette from '@/styles/Themes/Palette';
import { CloseIcon } from '@/shared/images/icons/CloseIcon';

export const Wrapper = styled.div`
    background-color: ${cssVars.bg_white_blue};
    width: 100%;
`;

export const Block = styled.div`
    max-width: 100%;
    position: relative;
    ${Screen.desktop} {
        padding: 0;
    }
`;

export const Header = styled.div`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
`;

export const ArrowBlock = styled.div`
    display: none;

    ${Screen.desktop} {
        width: 120px;
        display: flex;
        justify-content: end;
    }
`;

export const Title = styled.h2`
    color: ${cssVars.fg_gray1_white};
    margin: 0;
    ${Text.Full.heading2};
`;

export const CarouselWraper = styled.div`
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    width: calc(100% + 32px);
    padding: 0 16px;
    transform: translateX(-16px);

    ${Screen.ipad} {
        width: calc(100% + 48px);
        padding: 0 24px;
        transform: translateX(-24px);
    }

    &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(to left, transparent 0%, ${cssVars.bg_white_blue} 100%);
        background-image: -webkit-linear-gradient(
            0deg,
            ${cssVars.transparent_white_blue} 0%,
            ${cssVars.bg_white_blue} 100%
        );
        width: 16px;
        z-index: 1;
        opacity: 0.9;
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-image: linear-gradient(to right, transparent 0%, ${cssVars.bg_white_blue} 100%);
        background-image: -webkit-linear-gradient(
            180deg,
            ${cssVars.transparent_white_blue} 0%,
            ${cssVars.bg_white_blue} 100%
        );
        width: 16px;
        z-index: 1;
        opacity: 0.9;
    }
`;

export const Gallery = styled.div`
    display: flex;
    user-select: none;

    overflow-x: auto;
    scroll-behavior: smooth;
    transition: 0.5s ease 0s;
    box-sizing: border-box;

    ::-webkit-scrollbar {
        display: none;
    }

    width: calc(100% + 32px);
    padding: 16px;
    transform: translateX(-16px);

    ${Screen.ipad} {
        width: calc(100% + 48px);
        padding: 24px;
        transform: translateX(-24px);
    }
`;

export const InnerContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    width: auto;
    transition: 0.5s ease 0s;
    grid-gap: 12px;

    ${Screen.ipad} {
        grid-gap: 16px;
    }
    ${Screen.desktop} {
        grid-gap: 20px;
    }
`;

export const Card = styled.a<{ isActive?: boolean }>`
    display: block;
    cursor: pointer;
    position: relative;
    width: 158px;
    background: ${cssVars.bg_white_dark3};
    box-shadow: ${({ isActive }) =>
        !isActive ? `0px 3px 16px ${cssVars.shadow_normal}` : `0 0 0 2px ${Palette.green.normal}`};
    border-radius: ${radius.small};
    height: 100%;

    ${Screen.ipad} {
        max-width: 320px;
        width: 100%;
        box-shadow: ${({ isActive }) =>
            !isActive
                ? `0px 3px 23px ${cssVars.shadow_normal}`
                : `0 0 0 2px ${Palette.green.normal}`};
    }

    ${Screen.desktop} {
        width: 250px;
    }
`;

export const InnerCard = styled.div`
    width: 100%;
    min-width: 158px;
    padding: 12px 8px;
    display: flex;
    align-items: center;

    > svg {
        flex: none;
        width: 50px;
        height: 50px;
        margin-right: 8px;
    }

    ${Screen.ipad} {
        min-width: 300px;
        padding: 20px 24px;
        > svg {
            width: 65px;
            height: 65px;
        }
    }
    ${Screen.desktop} {
        min-width: 250px;
        padding: 16px;
    }
`;

export const CloseButton = styled(CloseIcon)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    /* background: ${cssVars.bg_ash_normal}; */
    background: ${cssVars.bg_white_dark3};
    box-shadow: ${cssVars.shadow_card_default};
    border-radius: ${radius.huge};
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
    padding: 12px;

    ${Screen.ipad} {
        width: 36px;
        height: 36px;
    }

    &:hover {
        background: ${cssVars.bg_ash_normal};
        box-shadow: none;
    }
`;

export const CategoryTitle = styled.span`
    color: ${cssVars.fg_gray1_white};
    width: 80px;
    ${Text.Full.medium.semibold}
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    ${Screen.ipad} {
        width: auto;
    }
`;

export const CenterLeftArrow = styled(LeftArrow)`
    position: absolute;
    left: 5px;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);

    ${Screen.desktop} {
        display: none;
    }
`;

export const CenterRightArrow = styled(RightArrow)`
    position: absolute;
    right: 5px;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);

    ${Screen.desktop} {
        display: none;
    }
`;

export const DotsSlider = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    transform: translateY(-16px);

    ${Screen.ipad} {
        display: none;
    }
`;

export const Description = styled.div`
    ${Text.Full.large.regular};
    margin: 10px 0 15px;
    color: ${cssVars.fg_gray1_white};

    ${Screen.desktop} {
        margin: 10px auto 20px;
    }
`;
