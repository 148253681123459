import styled from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import { PreloaderProps } from '.';

export const Wrapper = styled.div<PreloaderProps>`
    svg {
        width: 160px;
        height: 160px;
    }

    display: flex;
    justify-content: center;
    padding: 50px 0 50px;
    height: 50vh;
    min-height: ${({ minHeight }) => minHeight || 'auto'};
    max-height: ${({ maxHeight }) => maxHeight || 'auto'};
    align-items: ${({ align }) => align || 'start'};
    ${Screen.ipad} {
        svg {
            width: 180px;
            height: 180px;
        }
    }

    ${Screen.desktop} {
        svg {
            width: 200px;
            height: 200px;
        }
    }
`;
