import { ReactElement } from 'react';

export const InshoreBayFishing = (): ReactElement => {
    return (
        <svg
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M33.5 67C52.0015 67 67 52.0015 67 33.5C67 14.9985 52.0015 0 33.5 0C14.9985 0 0 14.9985 0 33.5C0 52.0015 14.9985 67 33.5 67Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M46.2299 44.6085C45.309 44.6111 44.3972 44.4265 43.5499 44.0658L42.1228 43.4628C41.3702 43.1429 40.5585 42.9859 39.7409 43.002C38.9234 43.0182 38.1185 43.2072 37.3792 43.5566L36.6087 43.9251C35.7059 44.3521 34.7197 44.5736 33.721 44.5736C32.7223 44.5736 31.7361 44.3521 30.8333 43.9251L30.0293 43.5499C29.2663 43.1955 28.4351 43.012 27.5938 43.012C26.7526 43.012 25.9214 43.1955 25.1584 43.5499L24.4549 43.8782C23.5192 44.3149 22.4959 44.5317 21.4635 44.512C20.4311 44.4922 19.4169 44.2365 18.4986 43.7643C17.6885 43.3545 16.7934 43.141 15.8856 43.141C14.9778 43.141 14.0827 43.3545 13.2726 43.7643C13.2132 43.7933 13.1487 43.8104 13.0827 43.8145C13.0167 43.8186 12.9506 43.8096 12.888 43.7882C12.8255 43.7667 12.7678 43.7331 12.7183 43.6894C12.6687 43.6456 12.6283 43.5925 12.5992 43.5331C12.5702 43.4738 12.5532 43.4092 12.5491 43.3432C12.545 43.2773 12.5539 43.2111 12.5754 43.1486C12.5968 43.0861 12.6304 43.0284 12.6742 42.9788C12.7179 42.9293 12.771 42.8888 12.8304 42.8598C13.7831 42.3877 14.833 42.1448 15.8963 42.1506C16.9595 42.1564 18.0067 42.4108 18.9542 42.8933C19.7366 43.2975 20.6013 43.5167 21.4818 43.5341C22.3623 43.5516 23.235 43.3667 24.0328 42.9938L24.7028 42.6655C25.5989 42.2484 26.5753 42.0324 27.5637 42.0324C28.5521 42.0324 29.5285 42.2484 30.4246 42.6655L31.2286 43.0407C31.9988 43.4032 32.8396 43.5911 33.6908 43.5911C34.5421 43.5911 35.3829 43.4032 36.1531 43.0407L36.9169 42.6722C37.7851 42.26 38.731 42.0367 39.6919 42.017C40.6528 41.9974 41.6069 42.1819 42.4913 42.5583L43.9184 43.1613C44.6879 43.4909 45.52 43.6485 46.3568 43.623C47.1935 43.5975 48.0145 43.3897 48.7625 43.0139C48.8819 42.9576 49.0185 42.9499 49.1435 42.9924C49.2685 43.0349 49.3722 43.1243 49.4325 43.2417C49.4631 43.3003 49.4815 43.3646 49.4866 43.4305C49.4916 43.4965 49.4833 43.5628 49.462 43.6254C49.4407 43.6881 49.4069 43.7457 49.3627 43.7949C49.3185 43.8441 49.2647 43.8838 49.2047 43.9117C48.2791 44.3675 47.2616 44.6058 46.2299 44.6085Z"
                fill="#0DD1C5"
            />
            <path
                d="M33.7078 49.2918C32.7192 49.2903 31.7428 49.0731 30.8469 48.6553L30.0429 48.2801C29.2799 47.9257 28.4487 47.7421 27.6074 47.7421C26.7661 47.7421 25.935 47.9257 25.172 48.2801L24.4685 48.6084C23.5333 49.0471 22.5098 49.2649 21.477 49.2452C20.4443 49.2254 19.4299 48.9686 18.5122 48.4945C18.4532 48.4646 18.4007 48.4233 18.3577 48.3731C18.3147 48.3229 18.282 48.2648 18.2615 48.2019C18.2409 48.1391 18.233 48.0728 18.2381 48.0069C18.2431 47.941 18.2611 47.8767 18.2911 47.8178C18.321 47.7588 18.3622 47.7063 18.4124 47.6633C18.4626 47.6203 18.5208 47.5876 18.5836 47.5671C18.6464 47.5465 18.7127 47.5386 18.7786 47.5437C18.8445 47.5487 18.9088 47.5668 18.9678 47.5967C19.7497 48.0023 20.6146 48.2224 21.4953 48.2398C22.376 48.2573 23.249 48.0716 24.0464 47.6972L24.7164 47.3756C25.611 46.9531 26.5879 46.7341 27.5772 46.7341C28.5665 46.7341 29.5436 46.9531 30.4382 47.3756L31.2422 47.7441C32.012 48.1079 32.8529 48.2966 33.7044 48.2966C34.5559 48.2966 35.3968 48.1079 36.1667 47.7441L36.9305 47.3823C37.7982 46.969 38.7438 46.7445 39.7047 46.7238C40.6656 46.703 41.62 46.8863 42.5049 47.2617C42.6275 47.3141 42.7242 47.4131 42.7739 47.5368C42.8235 47.6606 42.822 47.799 42.7695 47.9216C42.7171 48.0442 42.6181 48.141 42.4944 48.1906C42.3706 48.2403 42.2322 48.2387 42.1096 48.1863C41.3569 47.8657 40.5448 47.7089 39.727 47.7262C38.9091 47.7436 38.1043 47.9346 37.366 48.2868L36.5955 48.6486C35.6924 49.0742 34.7061 49.2939 33.7078 49.2918Z"
                fill="#0DD1C5"
            />
            <path
                d="M45.0037 17.7146C39.7352 18.0306 34.6927 19.9658 30.5652 23.2555C30.4936 23.2826 30.4294 23.3262 30.3777 23.3828C30.3777 23.3828 30.3777 23.3828 30.3777 23.4297C29.768 23.9322 29.2185 24.428 28.7227 24.9104C28.6586 24.9436 28.6017 24.9891 28.5552 25.0444C28.5552 25.0444 28.5552 25.0846 28.5552 25.098C27.3094 26.3128 26.2181 27.6765 25.3058 29.1582C25.2457 29.2741 25.233 29.4088 25.2705 29.5339C25.308 29.6589 25.3928 29.7645 25.5068 29.8282C25.581 29.8676 25.6638 29.8883 25.7479 29.8885C25.8383 29.8887 25.9271 29.8646 26.005 29.8186C26.0828 29.7726 26.1468 29.7065 26.1902 29.6272C26.9884 28.3155 27.9422 27.1052 29.031 26.0226L29.4664 26.3576C29.7656 26.5898 30.1329 26.717 30.5116 26.7194C30.945 26.72 31.3623 26.5545 31.6775 26.2571C31.8504 26.0924 31.9872 25.8936 32.0795 25.6734C32.1717 25.4531 32.2174 25.2161 32.2135 24.9774C32.2072 24.7384 32.1509 24.5035 32.0482 24.2877C31.9455 24.0718 31.7987 23.88 31.6172 23.7245C35.5233 20.7454 40.2275 18.9962 45.131 18.6995C45.2549 18.6791 45.3667 18.6132 45.4444 18.5145C45.522 18.4159 45.56 18.2918 45.5508 18.1666C45.5415 18.0414 45.4858 17.9242 45.3944 17.8381C45.3031 17.7519 45.1829 17.7031 45.0574 17.7012L45.0037 17.7146ZM30.9337 24.5017C31.0108 24.5645 31.0729 24.6437 31.1156 24.7335C31.1582 24.8233 31.1803 24.9215 31.1803 25.0209C31.1803 25.1203 31.1582 25.2185 31.1156 25.3083C31.0729 25.3981 31.0108 25.4773 30.9337 25.5402C30.8153 25.6588 30.6568 25.7287 30.4893 25.7362C30.3219 25.7436 30.1577 25.688 30.0293 25.5804L29.701 25.3258C30.036 25.0131 30.391 24.6982 30.7662 24.3811L30.9337 24.5017Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M53.9353 42.9202C53.831 42.9206 53.7292 42.8885 53.644 42.8285C53.5588 42.7685 53.4943 42.6834 53.4596 42.5852L45.3459 20.053C45.3049 19.9288 45.3139 19.7935 45.3711 19.6759C45.4283 19.5582 45.5291 19.4675 45.6521 19.423C45.7751 19.3785 45.9107 19.3838 46.0299 19.4376C46.1491 19.4914 46.2426 19.5896 46.2906 19.7114L54.411 42.2435C54.4375 42.3074 54.4505 42.3762 54.4491 42.4454C54.4478 42.5147 54.4322 42.5829 54.4033 42.6458C54.3743 42.7087 54.3327 42.765 54.281 42.811C54.2293 42.8571 54.1686 42.892 54.1028 42.9135C54.0477 42.9252 53.9911 42.9274 53.9353 42.9202Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M40.6221 32.2539H13.4335C13.3002 32.2539 13.1724 32.3068 13.0782 32.4011C12.984 32.4953 12.931 32.6231 12.931 32.7564C12.931 32.8897 12.984 33.0175 13.0782 33.1117C13.1724 33.206 13.3002 33.2589 13.4335 33.2589H17.0381V36.361H13.4C13.2668 36.361 13.1389 36.4139 13.0447 36.5082C12.9505 36.6024 12.8975 36.7302 12.8975 36.8635C12.8975 36.9968 12.9505 37.1246 13.0447 37.2188C13.1389 37.3131 13.2668 37.366 13.4 37.366H17.0448V41.2252C17.0448 41.3585 17.0978 41.4863 17.192 41.5805C17.2862 41.6748 17.4141 41.7277 17.5473 41.7277C17.6806 41.7277 17.8084 41.6748 17.9026 41.5805C17.9969 41.4863 18.0498 41.3585 18.0498 41.2252V37.366H33.3928V41.6741C33.3928 41.8074 33.4458 41.9352 33.54 42.0294C33.6343 42.1237 33.7621 42.1766 33.8953 42.1766C34.0286 42.1766 34.1564 42.1237 34.2506 42.0294C34.3449 41.9352 34.3978 41.8074 34.3978 41.6741V33.2589H40.6288C40.7621 33.2589 40.8899 33.206 40.9842 33.1117C41.0784 33.0175 41.1313 32.8897 41.1313 32.7564C41.1313 32.6231 41.0784 32.4953 40.9842 32.4011C40.8899 32.3068 40.7621 32.2539 40.6288 32.2539H40.6221ZM27.9323 33.2589V36.361H23.4902V33.2589H27.9323ZM18.0431 33.2589H22.4852V36.361H18.0431V33.2589ZM33.3861 36.361H28.9373V33.2589H33.3861V36.361Z"
                fill="var(--svg-gray1-gray3)"
            />
        </svg>
    );
};
