import * as S from './Preloader.styles';
import PreloaderIcon from '@/shared/images/icons/Preloader.svg';
import { useEffect, useState } from 'react';

export type PreloaderProps = { maxHeight?: string; minHeight?: string; align?: string };

export const Preloader = ({ maxHeight, minHeight, align }: PreloaderProps) => {
    const [mount, setMount] = useState<boolean>(false);

    useEffect(() => {
        if (!mount) {
            setMount(true);
        }
    }, [mount]);

    if (!mount) return <S.Wrapper maxHeight={maxHeight} minHeight={minHeight}></S.Wrapper>;

    return (
        <S.Wrapper align={align} maxHeight={maxHeight} minHeight={minHeight}>
            <PreloaderIcon />
        </S.Wrapper>
    );
};
