import { ReactElement } from 'react';

export const DeepSeaFishingCharters = (): ReactElement => {
    return (
        <svg
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M33.5 67C52.0015 67 67 52.0015 67 33.5C67 14.9985 52.0015 0 33.5 0C14.9985 0 0 14.9985 0 33.5C0 52.0015 14.9985 67 33.5 67Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M44.9701 46.9C44.1409 46.8984 43.3206 46.7298 42.5581 46.4042L41.2717 45.8548C40.5984 45.5703 39.8726 45.4311 39.1419 45.4461C38.4111 45.4611 37.6917 45.63 37.0306 45.9419L36.3606 46.2769C35.5392 46.6642 34.6423 46.8651 33.7342 46.8651C32.8261 46.8651 31.9292 46.6642 31.1078 46.2769L30.3842 45.9419C29.7022 45.6243 28.959 45.4598 28.2067 45.4598C27.4544 45.4598 26.7112 45.6243 26.0292 45.9419L25.3994 46.2367C24.5492 46.635 23.6188 46.8325 22.6801 46.8139C21.7414 46.7953 20.8196 46.5611 19.9858 46.1295C19.2593 45.76 18.4558 45.5673 17.6408 45.5673C16.8258 45.5673 16.0223 45.76 15.2958 46.1295C15.1781 46.1739 15.0482 46.1733 14.931 46.1276C14.8138 46.082 14.7176 45.9946 14.661 45.8822C14.6044 45.7699 14.5914 45.6406 14.6245 45.5192C14.6576 45.3979 14.7344 45.293 14.8402 45.225C15.7073 44.7943 16.6633 44.573 17.6314 44.5788C18.5995 44.5846 19.5528 44.8174 20.4146 45.2585C21.1136 45.6191 21.886 45.8146 22.6724 45.8297C23.4588 45.8448 24.2382 45.6791 24.9505 45.3456L25.5803 45.0508C26.3941 44.6702 27.2815 44.473 28.1799 44.473C29.0783 44.473 29.9657 44.6702 30.7795 45.0508L31.5031 45.3858C32.1904 45.7099 32.9408 45.8779 33.7007 45.8779C34.4606 45.8779 35.211 45.7099 35.8983 45.3858L36.5683 45.0575C37.3572 44.683 38.2164 44.4798 39.0894 44.4613C39.9624 44.4429 40.8295 44.6095 41.6335 44.9503L42.9199 45.4997C43.6089 45.7933 44.3535 45.9334 45.1021 45.9103C45.8507 45.8872 46.5853 45.7013 47.2548 45.3657C47.3739 45.3076 47.5112 45.299 47.6367 45.3416C47.7622 45.3843 47.8657 45.4748 47.9248 45.5935C47.9547 45.6519 47.9727 45.7157 47.9777 45.7811C47.9828 45.8465 47.9748 45.9123 47.9542 45.9747C47.9337 46.037 47.9009 46.0946 47.8579 46.1442C47.8149 46.1938 47.7625 46.2343 47.7037 46.2635C46.8535 46.6828 45.9181 46.9006 44.9701 46.9Z"
                fill="#0DD1C5"
            />
            <path
                d="M33.6882 51.1411C32.7893 51.1406 31.9017 50.9415 31.0886 50.5582L30.365 50.2232C29.683 49.9057 28.9398 49.7411 28.1875 49.7411C27.4352 49.7411 26.692 49.9057 26.01 50.2232L25.3802 50.518C24.5304 50.9178 23.5998 51.1161 22.6609 51.0976C21.7219 51.079 20.7999 50.8439 19.9666 50.4108C19.9018 50.3848 19.8431 50.3456 19.7943 50.2956C19.7454 50.2457 19.7076 50.1861 19.6831 50.1207C19.6585 50.0553 19.648 49.9854 19.6519 49.9157C19.6559 49.846 19.6744 49.7778 19.7062 49.7156C19.738 49.6534 19.7824 49.5986 19.8366 49.5545C19.8908 49.5104 19.9536 49.4781 20.021 49.4597C20.0884 49.4413 20.1588 49.4371 20.2279 49.4474C20.297 49.4577 20.3632 49.4824 20.4222 49.5197C21.1212 49.8804 21.8936 50.0758 22.68 50.0909C23.4664 50.106 24.2458 49.9404 24.9581 49.6068L25.5879 49.312C26.4017 48.9315 27.2891 48.7342 28.1875 48.7342C29.0859 48.7342 29.9733 48.9315 30.7871 49.312L31.5107 49.647C32.1975 49.9729 32.9481 50.142 33.7083 50.142C34.4685 50.142 35.2191 49.9729 35.9059 49.647L36.5759 49.3187C37.3648 48.9442 38.224 48.7411 39.097 48.7226C39.9701 48.7041 40.8371 48.8707 41.6411 49.2115C41.7028 49.2378 41.7587 49.276 41.8056 49.3239C41.8524 49.3719 41.8894 49.4286 41.9143 49.4908C41.9392 49.5531 41.9515 49.6196 41.9507 49.6867C41.9498 49.7537 41.9357 49.8199 41.9091 49.8815C41.8548 50.0049 41.7537 50.1017 41.6281 50.1506C41.5025 50.1996 41.3626 50.1968 41.2391 50.1428C40.5669 49.8579 39.8418 49.719 39.1118 49.7351C38.3819 49.7513 37.6637 49.9222 37.0047 50.2366L36.3347 50.5649C35.5062 50.9508 34.6022 51.1476 33.6882 51.1411Z"
                fill="#0DD1C5"
            />
            <path
                d="M39.6502 32.877C39.6038 32.8142 39.5432 32.7633 39.4733 32.7283C39.4035 32.6934 39.3263 32.6755 39.2482 32.676H25.0576L23.0007 27.584C22.6768 26.7856 22.1203 26.103 21.4033 25.6251C20.6864 25.1472 19.8423 24.8959 18.9807 24.904H15.3694C15.2362 24.904 15.1084 24.9569 15.0141 25.0512C14.9199 25.1454 14.8669 25.2732 14.8669 25.4065C14.8669 25.5398 14.9199 25.6676 15.0141 25.7618C15.1084 25.8561 15.2362 25.909 15.3694 25.909H19.0008C19.6608 25.9073 20.3061 26.1036 20.8532 26.4728C21.4003 26.8419 21.824 27.3667 22.0694 27.9793L23.9722 32.6693H15.3694C15.2362 32.6693 15.1084 32.7222 15.0141 32.8165C14.9199 32.9107 14.8669 33.0385 14.8669 33.1718C14.8669 33.3051 14.9199 33.4329 15.0141 33.5271C15.1084 33.6214 15.2362 33.6743 15.3694 33.6743H38.5782L37.3052 37.9958C37.0779 38.7583 36.6112 39.4274 35.974 39.904C35.3369 40.3806 34.5633 40.6395 33.7676 40.6423H15.5637C15.4305 40.6423 15.3027 40.6952 15.2084 40.7895C15.1142 40.8837 15.0612 41.0115 15.0612 41.1448C15.0612 41.2781 15.1142 41.4059 15.2084 41.5001C15.3027 41.5944 15.4305 41.6473 15.5637 41.6473H33.7676C34.779 41.649 35.7638 41.3237 36.5752 40.72C37.3866 40.1163 37.9811 39.2665 38.27 38.2973L39.7306 33.3326C39.7547 33.2558 39.76 33.1743 39.746 33.095C39.732 33.0157 39.6992 32.9409 39.6502 32.877Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M45.8822 19.8854C41.12 20.1693 36.5617 21.9173 32.8306 24.8903C32.7602 24.9162 32.6978 24.9601 32.6497 25.0176V25.0578C32.1092 25.5045 31.6134 25.9511 31.1623 26.3978C31.1 26.4308 31.0453 26.4765 31.0015 26.5318V26.5787C29.8737 27.6726 28.8878 28.9039 28.0669 30.2436C28.0068 30.3595 27.9942 30.4943 28.0317 30.6193C28.0692 30.7444 28.1539 30.8499 28.2679 30.9136C28.3422 30.9531 28.425 30.9738 28.5091 30.9739C28.5994 30.9734 28.6879 30.949 28.7656 30.9031C28.8433 30.8572 28.9074 30.7914 28.9513 30.7126C29.6588 29.5453 30.5046 28.4677 31.4705 27.5033L31.8323 27.7847C32.1103 28.0005 32.4519 28.1183 32.8038 28.1197C33.2061 28.1189 33.5925 27.9629 33.8825 27.6842C34.0448 27.5329 34.1734 27.3491 34.26 27.1447C34.3466 26.9404 34.3892 26.7202 34.385 26.4983C34.369 26.0709 34.1862 25.6668 33.8758 25.3727C37.3774 22.7107 41.5918 21.1504 45.9827 20.8904C46.0487 20.886 46.1132 20.8686 46.1725 20.8393C46.2317 20.81 46.2847 20.7693 46.3282 20.7195C46.3718 20.6698 46.4051 20.6119 46.4263 20.5492C46.4475 20.4866 46.4561 20.4204 46.4517 20.3544C46.4473 20.2884 46.43 20.2239 46.4006 20.1646C46.3713 20.1054 46.3306 20.0524 46.2808 20.0089C46.2311 19.9653 46.1732 19.932 46.1106 19.9108C46.0479 19.8896 45.9817 19.881 45.9157 19.8854H45.8822ZM33.1522 26.0963C33.214 26.1481 33.2639 26.2127 33.2986 26.2855C33.3333 26.3583 33.3519 26.4377 33.3532 26.5184C33.3546 26.5995 33.3393 26.6801 33.3082 26.755C33.2771 26.83 33.2308 26.8977 33.1723 26.9539C33.0719 27.0483 32.941 27.1036 32.8033 27.1097C32.6656 27.1159 32.5303 27.0725 32.4219 26.9874L32.1606 26.7998C32.442 26.5385 32.7301 26.2772 33.045 26.0159L33.1522 26.0963Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M51.9247 45.3926C51.8116 45.3913 51.7022 45.3524 51.6136 45.2821C51.525 45.2117 51.4624 45.1139 51.4356 45.004L46.1292 22.0096C46.0991 21.8798 46.1217 21.7435 46.1921 21.6304C46.2624 21.5173 46.3748 21.4368 46.5044 21.4066C46.5687 21.3897 46.6358 21.3862 46.7015 21.3961C46.7672 21.4061 46.8302 21.4293 46.8866 21.4644C46.9431 21.4996 46.9918 21.5458 47.0297 21.6004C47.0677 21.6549 47.0941 21.7166 47.1074 21.7817L52.4138 44.7762C52.4422 44.9057 52.4188 45.0412 52.3487 45.1539C52.2786 45.2665 52.1674 45.3473 52.0386 45.3792L51.9247 45.3926Z"
                fill="var(--svg-gray1-gray3)"
            />
        </svg>
    );
};
