import styled, { css } from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import { cssVars } from '@/styles/Themes/cssVars';
import { radius } from '@/styles/Themes/Metrics';
import { DropdownArrow } from '@/shared/images/icons/DropdownArrow';
import { Transition } from '@/styles/Themes/KeyFrames';

export const CommonContainer = styled.div`
    width: 100%;
    max-width: 1124px;
    margin: 0 auto;
    padding: 0 16px;
    display: flex;
    flex-direction: column-reverse;

    ${Screen.ipad} {
        padding: 0 29px;
    }
    ${Screen.desktop} {
        flex-direction: row;
        padding: 0 30px;
        > * {
            &:nth-child(2) {
                margin-left: 16px;
            }
        }
    }
`;

export const FAQWrappper = styled.div`
    margin: 15px 0 50px;
    min-width: 50%;
    width: 100%;

    ${Screen.ipad} {
        margin: 0 0 60px;
    }
`;

export const Title = styled.h4`
    color: ${cssVars.fg_gray1_white};
    ${Text.Mobile.heading2}

    margin: 44px 0 25px;

    ${Screen.ipad} {
        ${Text.Ipad.heading3}
    }
    ${Screen.desktop} {
        margin: 0 0 40px;
        ${Text.Desktop.heading2}
    }
`;

export const Item = styled.div`
    background-color: ${cssVars.bg_white_dark3};
    border-radius: ${radius.small};
    box-shadow: 0px 10px 43px ${cssVars.shadow_normal};

    margin-bottom: 10px;
    padding: 0 20px;
    overflow: hidden;
    position: relative;

    a {
        color: ${cssVars.fg_green_greenLight};
        text-decoration: underline;
        font-weight: 500;
    }

    p {
        margin-top: 0;
        margin-bottom: 0;
    }

    ul,
    ol {
        padding-left: 10px;
        margin: 0;
    }

    ${Screen.ipad} {
        margin-bottom: 16px;
        padding: 0 30px;
    }
`;

export const Question = styled.div`
    color: ${cssVars.fg_gray1_white};
    ${Text.Full.large.semibold}
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    padding: 22px 0;
`;

export const Answer = styled.div`
    color: ${cssVars.fg_gray2_gray3};
    ${Text.Full.medium.regular}
    border-top: 1px solid ${cssVars.bg_gray4_dark2};

    padding: 22px 0;

    ${Screen.ipad} {
        padding: 30px 0;
    }
`;

export const Animation = styled.div<{ isActive: boolean }>`
    ${({ isActive }) =>
        isActive
            ? css`
                  position: relative;

                  @keyframes down {
                      from {
                          max-height: 0;
                      }

                      to {
                          max-height: 100vh;
                      }
                  }

                  animation-duration: 2s;
                  animation-name: down;
                  animation-timing-function: ease-out;
              `
            : css`
                  position: absolute;
              `}
`;

export const Arrow = styled(DropdownArrow)<{ isActive: boolean }>`
    margin-left: 10px;
    ${({ isActive }) => isActive && 'transform: rotate(-180deg);'}
    ${Transition.arrow};
    min-width: 24px;
    min-height: 24px;

    ${Screen.ipad} {
        min-width: 24px;
        min-height: 24px;
    }
    ${Screen.desktop} {
        min-width: 35px;
        min-height: 34px;
    }
`;
