import { ReactElement } from 'react';

export const DinnerCruises = (): ReactElement => {
    return (
        <svg
            width="67"
            height="67"
            viewBox="0 0 67 67"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M33.5 67C52.0015 67 67 52.0015 67 33.5C67 14.9985 52.0015 0 33.5 0C14.9985 0 0 14.9985 0 33.5C0 52.0015 14.9985 67 33.5 67Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M25.3397 48.4342L23.5374 41.2317H24.529C24.6623 41.2317 24.7901 41.1788 24.8843 41.0845C24.9786 40.9903 25.0315 40.8625 25.0315 40.7292C25.0315 40.5959 24.9786 40.4681 24.8843 40.3739C24.7901 40.2796 24.6623 40.2267 24.529 40.2267H18.2846C18.0955 40.225 17.9086 40.1857 17.7348 40.1111C17.561 40.0365 17.4037 39.9281 17.2722 39.7922C17.1406 39.6563 17.0374 39.4956 16.9685 39.3194C16.8996 39.1433 16.8665 38.9552 16.8709 38.7661L16.2478 30.8936C16.2372 30.7603 16.174 30.6367 16.0722 30.55C15.9704 30.4634 15.8384 30.4206 15.7051 30.4313C15.5718 30.442 15.4483 30.5051 15.3616 30.6069C15.2749 30.7087 15.2322 30.8407 15.2428 30.974L15.8659 38.8063C15.8665 39.3459 16.0475 39.8699 16.3801 40.2948C16.7128 40.7198 17.178 41.0212 17.7017 41.1513L15.8793 48.4342C15.8488 48.5638 15.8703 48.7002 15.9392 48.8142C16.0081 48.9282 16.1189 49.0106 16.2478 49.0439C16.2877 49.0505 16.3285 49.0505 16.3684 49.0439C16.48 49.0446 16.5887 49.008 16.6773 48.9401C16.7658 48.8722 16.8292 48.7767 16.8575 48.6687L18.7201 41.2183H22.5056L24.3682 48.6687C24.3952 48.7761 24.4573 48.8715 24.5447 48.9395C24.6322 49.0075 24.7399 49.0442 24.8506 49.0439C24.8905 49.0505 24.9313 49.0505 24.9712 49.0439C25.0355 49.0282 25.0961 49 25.1495 48.9608C25.2028 48.9216 25.2479 48.8723 25.2822 48.8156C25.3164 48.759 25.3391 48.6961 25.349 48.6307C25.3589 48.5652 25.3557 48.4984 25.3397 48.4342Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M51.1347 38.8602L51.7578 30.9877C51.7685 30.8544 51.7258 30.7223 51.6391 30.6206C51.5524 30.5188 51.4288 30.4556 51.2955 30.445C51.1622 30.4343 51.0302 30.477 50.9284 30.5637C50.8266 30.6504 50.7635 30.774 50.7528 30.9073L50.1297 38.82C50.1297 39.1955 49.981 39.5558 49.7161 39.822C49.4511 40.0882 49.0916 40.2386 48.716 40.2404H42.4716C42.3383 40.2404 42.2105 40.2933 42.1163 40.3875C42.0221 40.4818 41.9691 40.6096 41.9691 40.7429C41.9691 40.8761 42.0221 41.004 42.1163 41.0982C42.2105 41.1924 42.3383 41.2454 42.4716 41.2454H43.4632L41.6609 48.4479C41.6449 48.5121 41.6417 48.5789 41.6516 48.6443C41.6615 48.7098 41.6842 48.7726 41.7184 48.8293C41.7527 48.886 41.7978 48.9353 41.8512 48.9745C41.9045 49.0136 41.9651 49.0419 42.0294 49.0576C42.0693 49.0642 42.1101 49.0642 42.15 49.0576C42.2608 49.0579 42.3685 49.0211 42.4559 48.9531C42.5433 48.8851 42.6055 48.7898 42.6324 48.6824L44.495 41.232H48.2805L50.1431 48.6824C50.1714 48.7903 50.2348 48.8858 50.3234 48.9538C50.4119 49.0217 50.5206 49.0582 50.6322 49.0576C50.6721 49.0642 50.7129 49.0642 50.7528 49.0576C50.8818 49.0243 50.9926 48.9419 51.0614 48.8279C51.1303 48.7139 51.1518 48.5775 51.1213 48.4479L49.2989 41.165C49.8172 41.0387 50.2787 40.7438 50.611 40.3266C50.9434 39.9094 51.1276 39.3935 51.1347 38.8602Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M44.2671 35.3291H38.9071C38.872 34.0117 38.3558 32.7528 37.4559 31.79C36.5561 30.8271 35.3348 30.2271 34.0228 30.1031V29.6006C34.0228 29.4674 33.9699 29.3396 33.8756 29.2453C33.7814 29.1511 33.6536 29.0981 33.5203 29.0981C33.387 29.0981 33.2592 29.1511 33.165 29.2453C33.0707 29.3396 33.0178 29.4674 33.0178 29.6006V30.1031C31.7058 30.2271 30.4845 30.8271 29.5847 31.79C28.6848 32.7528 28.1686 34.0117 28.1335 35.3291H22.7735C22.6402 35.3291 22.5124 35.3821 22.4182 35.4763C22.3239 35.5706 22.271 35.6984 22.271 35.8316C22.271 35.9649 22.3239 36.0927 22.4182 36.187C22.5124 36.2812 22.6402 36.3341 22.7735 36.3341H33.0379V48.0524H31.0949C30.9616 48.0524 30.8338 48.1054 30.7396 48.1996C30.6453 48.2939 30.5924 48.4217 30.5924 48.5549C30.5924 48.6882 30.6453 48.816 30.7396 48.9103C30.8338 49.0045 30.9616 49.0574 31.0949 49.0574H35.9859C36.1192 49.0574 36.247 49.0045 36.3412 48.9103C36.4354 48.816 36.4884 48.6882 36.4884 48.5549C36.4884 48.4217 36.4354 48.2939 36.3412 48.1996C36.247 48.1054 36.1192 48.0524 35.9859 48.0524H34.0429V36.3341H44.3073C44.4406 36.3341 44.5684 36.2812 44.6626 36.187C44.7568 36.0927 44.8098 35.9649 44.8098 35.8316C44.8098 35.6984 44.7568 35.5706 44.6626 35.4763C44.5684 35.3821 44.4406 35.3291 44.3073 35.3291H44.2671ZM33.5002 31.0813C34.6385 31.0842 35.7312 31.5285 36.5485 32.3208C37.3658 33.1131 37.8438 34.1915 37.882 35.3291H29.1184C29.1566 34.1915 29.6346 33.1131 30.4519 32.3208C31.2692 31.5285 32.3619 31.0842 33.5002 31.0813Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M27.1345 26.626C27.0311 26.6269 26.9299 26.5958 26.8448 26.5371C26.7596 26.4783 26.6947 26.3947 26.6588 26.2977L26.5181 25.9091C26.1063 24.7837 25.398 23.7905 24.4679 23.0348C24.4113 22.9864 24.3659 22.9262 24.3348 22.8586C24.3036 22.7909 24.2875 22.7173 24.2875 22.6428C24.2875 22.5683 24.3036 22.4947 24.3348 22.4271C24.3659 22.3594 24.4113 22.2993 24.4679 22.2509C25.398 21.4951 26.1063 20.502 26.5181 19.3766L26.6588 18.988C26.6947 18.8909 26.7596 18.8073 26.8448 18.7486C26.9299 18.6898 27.0311 18.6588 27.1345 18.6597C27.2371 18.6592 27.3372 18.6905 27.4212 18.7493C27.5052 18.8081 27.5689 18.8915 27.6035 18.988L27.7509 19.3766C28.1632 20.5 28.8688 21.4925 29.7944 22.2509C29.8531 22.298 29.9005 22.3576 29.933 22.4255C29.9656 22.4933 29.9825 22.5676 29.9825 22.6428C29.9825 22.7181 29.9656 22.7924 29.933 22.8602C29.9005 22.928 29.8531 22.9877 29.7944 23.0348C28.8688 23.7932 28.1632 24.7857 27.7509 25.9091L27.6035 26.2977C27.5689 26.3942 27.5052 26.4776 27.4212 26.5364C27.3372 26.5952 27.2371 26.6265 27.1345 26.626ZM25.5466 22.606C26.1991 23.236 26.7365 23.9752 27.1345 24.7902C27.5345 23.9823 28.0718 23.2501 28.7224 22.6261C28.0705 22.0058 27.533 21.2755 27.1345 20.4687C26.7374 21.2803 26.1998 22.0152 25.5466 22.6395V22.606Z"
                fill="#0DD1C5"
            />
            <path
                d="M39.0276 20.6894C38.9225 20.6893 38.8201 20.6563 38.7348 20.595C38.6495 20.5337 38.5855 20.4472 38.5519 20.3477L38.438 20.0261C38.1271 19.1128 37.5819 18.2973 36.8568 17.661C36.8017 17.6144 36.7575 17.5565 36.7272 17.4911C36.6969 17.4257 36.6812 17.3545 36.6812 17.2824C36.6812 17.2104 36.6969 17.1392 36.7272 17.0738C36.7575 17.0084 36.8017 16.9504 36.8568 16.9039C37.5819 16.2676 38.1271 15.452 38.438 14.5388L38.5519 14.2172C38.5855 14.1176 38.6495 14.0312 38.7348 13.9699C38.8201 13.9086 38.9225 13.8756 39.0276 13.8755C39.1326 13.8756 39.235 13.9086 39.3204 13.9699C39.4057 14.0312 39.4697 14.1176 39.5033 14.2172L39.6105 14.5388C39.9263 15.4512 40.4734 16.2661 41.1984 16.9039C41.2534 16.9504 41.2976 17.0084 41.328 17.0738C41.3583 17.1392 41.374 17.2104 41.374 17.2824C41.374 17.3545 41.3583 17.4257 41.328 17.4911C41.2976 17.5565 41.2534 17.6144 41.1984 17.661C40.4734 18.2987 39.9263 19.1136 39.6105 20.0261L39.5033 20.3477C39.4697 20.4472 39.4057 20.5337 39.3204 20.595C39.235 20.6563 39.1326 20.6893 39.0276 20.6894ZM37.9154 17.2791C38.3615 17.7439 38.7363 18.2722 39.0276 18.8469C39.3188 18.2722 39.6936 17.7439 40.1398 17.2791C39.6922 16.818 39.3172 16.2916 39.0276 15.718C38.7379 16.2916 38.3629 16.818 37.9154 17.2791Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
