import { ReactElement } from 'react';

export const ManateeTours = (): ReactElement => {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50 100C59.889 100 69.556 97.0676 77.7785 91.5735C86.0009 86.0794 92.4096 78.2705 96.1939 69.1342C99.9783 59.9979 100.969 49.9445 99.0392 40.2455C97.11 30.5464 92.3479 21.6373 85.3553 14.6447C78.3627 7.65206 69.4535 2.89002 59.7545 0.960758C50.0555 -0.968502 40.0021 0.0216641 30.8658 3.80604C21.7295 7.59043 13.9206 13.9991 8.42651 22.2215C2.93244 30.444 0 40.1109 0 50C0 63.2608 5.26783 75.9785 14.6446 85.3553C24.0215 94.7322 36.7392 100 50 100Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M55.2695 42.6225C55.5163 42.2898 55.986 42.2203 56.3187 42.4671C61.3033 46.1653 62.8959 49.9747 62.3487 52.9033C61.8015 55.8313 59.1261 57.608 56.3436 57.0117C53.687 56.4424 52.1847 54.6826 51.3688 52.7739C50.5643 50.8916 50.4043 48.8262 50.4043 47.4724C50.4043 47.0582 50.7401 46.7224 51.1543 46.7224C51.5685 46.7224 51.9043 47.0582 51.9043 47.4724C51.9043 48.7394 52.0588 50.5715 52.7481 52.1843C53.4262 53.7707 54.5971 55.1034 56.6579 55.545C58.593 55.9597 60.4778 54.7486 60.8742 52.6278C61.2704 50.5075 60.1898 47.207 55.4249 43.6717C55.0923 43.4249 55.0227 42.9552 55.2695 42.6225Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45.0093 53.3465C45.4129 53.2534 45.8156 53.5051 45.9087 53.9087C46.0256 54.4151 46.4833 55.2547 47.2397 56.0063C47.9862 56.748 48.9355 57.3161 49.97 57.4195C52.0188 57.6244 53.0534 56.2671 53.6553 55.2639C53.8684 54.9088 54.3291 54.7936 54.6843 55.0067C55.0395 55.2198 55.1546 55.6805 54.9415 56.0357C54.2854 57.1292 52.804 59.2104 49.8208 58.9121C48.3393 58.7639 47.0871 57.9692 46.1824 57.0703C45.2877 56.1812 44.6447 55.1024 44.4471 54.246C44.354 53.8424 44.6056 53.4397 45.0093 53.3465Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.5661 24.4206C53.7526 22.5231 56.5931 21.25 59.3278 21.25C62.4724 21.25 65.3342 22.5366 67.7203 24.2462C70.108 25.9569 72.0656 28.1233 73.4215 29.9714C74.4504 31.3738 74.8593 33.0728 74.8593 34.7324V38.0395C74.8593 41.2328 72.2706 43.8215 69.0773 43.8215H65.9323C62.0442 43.8215 58.8923 40.6696 58.8923 36.7815V36.467C58.8923 36.0528 59.2281 35.717 59.6423 35.717C60.0565 35.717 60.3923 36.0528 60.3923 36.467V36.7815C60.3923 39.8411 62.8727 42.3215 65.9323 42.3215H69.0773C71.4422 42.3215 73.3593 40.4044 73.3593 38.0395V34.7324C73.3593 33.3076 73.0077 31.9432 72.2121 30.8587C70.9236 29.1025 69.0739 27.0613 66.8467 25.4656C64.618 23.8687 62.0574 22.75 59.3278 22.75C57.034 22.75 54.5298 23.8347 52.5493 25.5535C50.5671 27.2736 49.2116 29.5383 49.0688 31.7968C49.0427 32.2102 48.6864 32.5241 48.273 32.498C47.8596 32.4719 47.5457 32.1156 47.5718 31.7022C47.7473 28.9269 49.3812 26.3168 51.5661 24.4206Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M48.375 23.0055C50.5324 21.9032 52.6729 22.2343 54.0051 22.9004C54.3755 23.0856 54.5257 23.5361 54.3405 23.9066C54.1552 24.2771 53.7047 24.4272 53.3342 24.242C52.36 23.7549 50.7265 23.4885 49.0575 24.3413C47.3948 25.1908 45.5526 27.2268 44.3283 31.6344C44.2174 32.0335 43.804 32.2672 43.4049 32.1563C43.0058 32.0454 42.7722 31.632 42.883 31.2329C44.1747 26.5829 46.2113 24.1111 48.375 23.0055Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M46.2218 26.2217C46.3206 26.6239 46.0746 27.0301 45.6723 27.1289C40.0134 28.5188 26.9228 36.8647 21.6928 59.279C21.6894 59.2937 21.6855 59.3084 21.6812 59.3229C20.7995 62.279 19.8286 66.6881 19.7545 70.3279C19.7173 72.1589 19.91 73.7056 20.3874 74.7666C20.6217 75.2874 20.9108 75.66 21.2454 75.9027C21.5726 76.14 21.9865 76.285 22.5349 76.285C23.611 76.285 24.3998 75.7687 25.0084 74.81C25.6391 73.8164 26.0464 72.3799 26.2647 70.6884C26.6996 67.3175 26.3488 63.2314 25.8835 60.4921C25.8556 60.3274 25.8834 60.1581 25.9626 60.011C26.7673 58.5165 29.3801 55.3186 33.6327 53.9897C35.9653 53.2608 38.6112 53.2399 41.2619 53.2189C41.6209 53.2161 41.9799 53.2133 42.3383 53.2087C45.3971 53.1695 48.4986 53.01 51.5044 51.8077C51.889 51.6539 52.3255 51.8409 52.4793 52.2255C52.6331 52.6101 52.4461 53.0466 52.0615 53.2004C48.7773 54.5141 45.4315 54.6691 42.3576 54.7085C41.9905 54.7133 41.6285 54.7164 41.2714 54.7195C38.5857 54.743 36.184 54.7639 34.0802 55.4214C30.4885 56.5438 28.205 59.182 27.4065 60.5068C27.8631 63.3204 28.1993 67.4167 27.7524 70.8803C27.5225 72.6614 27.0769 74.3503 26.2747 75.6139C25.4505 76.9123 24.2265 77.785 22.5349 77.785C21.6996 77.785 20.9708 77.5566 20.3647 77.117C19.7661 76.6828 19.3313 76.075 19.0195 75.3821C18.4047 74.0155 18.2161 72.201 18.2548 70.2973C18.3326 66.4769 19.3384 61.9355 20.2372 58.916C25.5779 36.0588 39.0245 27.2171 45.3145 25.6722C45.7168 25.5734 46.123 25.8194 46.2218 26.2217Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M62.9343 41.5315C63.2627 41.7841 63.3241 42.255 63.0715 42.5833L59.9265 46.6718C59.674 47.0001 59.2031 47.0615 58.8748 46.809C58.5465 46.5564 58.4851 46.0855 58.7376 45.7572L61.8826 41.6687C62.1351 41.3404 62.606 41.279 62.9343 41.5315Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M65.2695 39.1387C65.2695 39.5529 64.9337 39.8887 64.5195 39.8887L64.205 39.8887C63.7908 39.8887 63.455 39.5529 63.455 39.1387C63.455 38.7245 63.7908 38.3887 64.205 38.3887L64.5195 38.3887C64.9337 38.3887 65.2695 38.7245 65.2695 39.1387Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.6289 37.0947C67.6289 37.5089 67.2931 37.8447 66.8789 37.8447L66.5644 37.8447C66.1502 37.8447 65.8144 37.5089 65.8144 37.0947C65.8144 36.6805 66.1502 36.3447 66.5644 36.3447L66.8789 36.3447C67.2931 36.3447 67.6289 36.6805 67.6289 37.0947Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64.4844 36.4648C64.4844 36.8791 64.1486 37.2148 63.7344 37.2148L63.4199 37.2148C63.0057 37.2148 62.6699 36.8791 62.6699 36.4648C62.6699 36.0506 63.0057 35.7148 63.4199 35.7148L63.7344 35.7148C64.1486 35.7148 64.4844 36.0506 64.4844 36.4648Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M72 49.75C70.2051 49.75 68.75 51.2051 68.75 53C68.75 54.7949 70.2051 56.25 72 56.25C73.7949 56.25 75.25 54.7949 75.25 53C75.25 51.2051 73.7949 49.75 72 49.75ZM67.25 53C67.25 50.3766 69.3766 48.25 72 48.25C74.6234 48.25 76.75 50.3766 76.75 53C76.75 55.6234 74.6234 57.75 72 57.75C69.3766 57.75 67.25 55.6234 67.25 53Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M68 64.75C66.7574 64.75 65.75 65.7574 65.75 67C65.75 68.2426 66.7574 69.25 68 69.25C69.2426 69.25 70.25 68.2426 70.25 67C70.25 65.7574 69.2426 64.75 68 64.75ZM64.25 67C64.25 64.9289 65.9289 63.25 68 63.25C70.0711 63.25 71.75 64.9289 71.75 67C71.75 69.0711 70.0711 70.75 68 70.75C65.9289 70.75 64.25 69.0711 64.25 67Z"
                fill="#0DD1C5"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78 61.75C77.3096 61.75 76.75 62.3096 76.75 63C76.75 63.6904 77.3096 64.25 78 64.25C78.6904 64.25 79.25 63.6904 79.25 63C79.25 62.3096 78.6904 61.75 78 61.75ZM75.25 63C75.25 61.4812 76.4812 60.25 78 60.25C79.5188 60.25 80.75 61.4812 80.75 63C80.75 64.5188 79.5188 65.75 78 65.75C76.4812 65.75 75.25 64.5188 75.25 63Z"
                fill="#0DD1C5"
            />
        </svg>
    );
};
