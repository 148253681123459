import { ReactElement } from 'react';

export const SwimmingWithDolphins = (): ReactElement => {
    return (
        <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M50 100C59.889 100 69.556 97.0676 77.7785 91.5735C86.0009 86.0794 92.4096 78.2705 96.1939 69.1342C99.9783 59.9979 100.969 49.9445 99.0392 40.2455C97.11 30.5464 92.3479 21.6373 85.3553 14.6447C78.3627 7.65206 69.4535 2.89002 59.7545 0.960758C50.0555 -0.968502 40.0021 0.0216641 30.8658 3.80604C21.7295 7.59043 13.9206 13.9991 8.42651 22.2215C2.93244 30.444 0 40.1109 0 50C0 63.2608 5.26783 75.9785 14.6446 85.3553C24.0215 94.7322 36.7392 100 50 100Z"
                fill="var(--svg-ash-dark2)"
            />
            <path
                d="M44.7876 69.9531C42.9714 69.9541 41.1781 69.5484 39.5392 68.7656L38.1397 68.0976C36.7763 67.4471 35.2896 67.0954 33.7792 67.0661C32.2688 67.0369 30.7695 67.3307 29.3819 67.928C29.2847 67.9715 29.1797 67.995 29.0733 67.9971C28.9668 67.9992 28.8609 67.9799 28.762 67.9404C28.6631 67.9008 28.5732 67.8418 28.4976 67.7668C28.4219 67.6918 28.3621 67.6024 28.3217 67.5039C28.2797 67.4065 28.2573 67.3017 28.2559 67.1956C28.2545 67.0895 28.2741 66.9842 28.3135 66.8857C28.3529 66.7872 28.4114 66.6974 28.4856 66.6215C28.5597 66.5457 28.6482 66.4852 28.7458 66.4436C30.3381 65.7685 32.0556 65.4392 33.7847 65.4776C35.5138 65.516 37.2151 65.9212 38.7758 66.6663L40.1648 67.3237C41.5791 67.9905 43.1233 68.3363 44.6868 68.3363C46.2504 68.3363 47.7946 67.9905 49.2089 67.3237L50.672 66.6451C52.2832 65.8907 54.0405 65.4997 55.8196 65.4997C57.5986 65.4997 59.356 65.8907 60.9672 66.6451L62.2395 67.2388C63.7054 67.9225 65.3083 68.2617 66.9255 68.2304C68.5427 68.1992 70.1314 67.7983 71.5698 67.0586C71.7575 66.9687 71.9727 66.9549 72.1703 67.0201C72.368 67.0854 72.5327 67.2245 72.63 67.4085C72.7227 67.5957 72.7379 67.8119 72.6725 68.0103C72.607 68.2087 72.4661 68.3734 72.2801 68.4687C70.6283 69.3215 68.8026 69.7832 66.944 69.8181C65.0854 69.853 63.2436 69.4603 61.5609 68.6702L60.2886 68.0764C58.8857 67.4225 57.3567 67.0836 55.809 67.0836C54.2612 67.0836 52.7322 67.4225 51.3294 68.0764L49.8662 68.755C48.2788 69.514 46.5468 69.9226 44.7876 69.9531Z"
                fill="#0DD1C5"
            />
            <path
                d="M23.0197 59.9774C21.1237 59.9766 19.2541 59.5337 17.5593 58.6839C17.3734 58.5886 17.2325 58.4239 17.167 58.2255C17.1015 58.0271 17.1168 57.8109 17.2094 57.6237C17.2556 57.5306 17.3197 57.4476 17.3982 57.3796C17.4766 57.3115 17.5678 57.2597 17.6665 57.2272C17.7651 57.1946 17.8692 57.182 17.9728 57.19C18.0763 57.198 18.1772 57.2265 18.2697 57.2738C19.6439 57.9616 21.1512 58.3426 22.6872 58.3902C24.2232 58.4378 25.7512 58.1509 27.1653 57.5494L29.7629 56.4892C31.3532 55.8146 33.0684 55.4847 34.7954 55.5213C36.5224 55.5578 38.2221 55.96 39.7824 56.7012L41.1714 57.3692C42.5842 58.0344 44.1265 58.3794 45.6881 58.3794C47.2497 58.3794 48.792 58.0344 50.2048 57.3692L51.6574 56.6906C53.2694 55.9391 55.0264 55.5497 56.8049 55.5497C58.5835 55.5497 60.3405 55.9391 61.9525 56.6906L63.2142 57.2844C64.6787 57.9663 66.2797 58.3046 67.8949 58.2734C69.5101 58.2421 71.0969 57.8422 72.5339 57.1041C74.2425 56.2437 76.1289 55.7955 78.042 55.7955C79.955 55.7955 81.8414 56.2437 83.55 57.1041C83.7359 57.1996 83.8773 57.3636 83.9446 57.5614C84.0118 57.7592 83.9996 57.9754 83.9105 58.1644C83.8152 58.3503 83.6505 58.4912 83.4521 58.5567C83.2537 58.6222 83.0375 58.6069 82.8503 58.5143C81.3704 57.7685 79.7363 57.3801 78.0791 57.3801C76.4219 57.3801 74.7878 57.7685 73.3079 58.5143C71.6563 59.3615 69.8329 59.8201 67.9769 59.855C66.121 59.8899 64.2816 59.5002 62.5993 58.7157L61.327 58.122C59.9255 57.4696 58.3984 57.1316 56.8526 57.1316C55.3069 57.1316 53.7798 57.4696 52.3783 58.122L50.9258 58.8005C49.2973 59.5591 47.521 59.9477 45.7246 59.9385C43.9281 59.9293 42.1559 59.5226 40.5352 58.7475L39.1463 58.0902C37.7901 57.4425 36.3117 57.0905 34.8091 57.0576C33.3065 57.0247 31.8141 57.3116 30.4309 57.8993L27.8439 58.9596C26.3215 59.6227 24.6801 59.969 23.0197 59.9774Z"
                fill="#0DD1C5"
            />
            <path
                d="M55.7397 50.2174C55.5784 50.2176 55.4209 50.1688 55.288 50.0774C55.1551 49.986 55.0531 49.8564 54.9956 49.7057C54.9381 49.555 54.9278 49.3904 54.966 49.2338C55.0042 49.0771 55.0892 48.9357 55.2096 48.8284C57.16 47.0685 59.489 45.7807 62.0165 45.0645C63.4073 44.6752 64.8248 44.3882 66.2575 44.2057C66.3607 44.1924 66.4655 44.1997 66.5658 44.2272C66.6661 44.2548 66.7599 44.302 66.8418 44.3661C66.9236 44.4302 66.992 44.51 67.0428 44.6008C67.0935 44.6915 67.1257 44.7915 67.1375 44.8948C67.1509 44.9986 67.1437 45.104 67.1163 45.2049C67.0889 45.3059 67.0418 45.4004 66.9778 45.4832C66.9138 45.5659 66.834 45.6352 66.7432 45.6871C66.6524 45.7389 66.5522 45.7724 66.4484 45.7855C65.0884 45.9609 63.7422 46.2302 62.4194 46.5913C60.133 47.2513 58.0266 48.4223 56.2593 50.0159C56.1161 50.1435 55.9315 50.2151 55.7397 50.2174Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M83.2645 50.2166C83.0831 50.2128 82.908 50.1495 82.7662 50.0364C79.1453 47.3044 74.7379 45.8167 70.2021 45.7954C70.0967 45.7913 69.9932 45.7663 69.8975 45.7218C69.8019 45.6774 69.716 45.6143 69.645 45.5364C69.5739 45.4584 69.5191 45.3671 69.4837 45.2678C69.4483 45.1684 69.433 45.0631 69.4387 44.9577C69.4427 44.8528 69.4677 44.7497 69.5123 44.6546C69.5569 44.5595 69.6201 44.4744 69.6982 44.4042C69.7763 44.334 69.8678 44.2802 69.967 44.2459C70.0663 44.2117 70.1715 44.1978 70.2763 44.205C75.1533 44.2576 79.8832 45.8826 83.7628 48.8383C83.8455 48.9034 83.9144 48.9842 83.9656 49.0761C84.0168 49.168 84.0492 49.2691 84.0611 49.3737C84.0729 49.4782 84.0639 49.5841 84.0345 49.6851C84.0052 49.7861 83.9561 49.8803 83.89 49.9622C83.81 50.0468 83.7127 50.1132 83.6048 50.1571C83.4969 50.201 83.3809 50.2213 83.2645 50.2166Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                d="M73.4867 45.7105C73.3177 45.7102 73.153 45.6568 73.016 45.5579C72.8791 45.459 72.7767 45.3195 72.7233 45.1591C72.6385 44.8941 70.677 39.105 65.153 39.1898C66.1602 40.4622 67.4644 42.5615 67.1887 44.7456C67.1634 44.9551 67.0559 45.146 66.8899 45.2762C66.7238 45.4064 66.5129 45.4654 66.3034 45.4401C66.0939 45.4148 65.903 45.3073 65.7728 45.1413C65.6426 44.9752 65.5836 44.7643 65.6089 44.5548C65.8846 42.3706 63.7959 39.9108 62.9689 39.1368C62.863 39.0365 62.7868 38.909 62.7485 38.7683C62.7103 38.6275 62.7115 38.479 62.752 38.3389C62.7925 38.1988 62.8707 38.0725 62.9782 37.9739C63.0856 37.8752 63.2181 37.8081 63.3612 37.7797C71.4404 36.2847 74.2076 44.5866 74.2395 44.6714C74.3031 44.8711 74.2853 45.0878 74.19 45.2744C74.0947 45.461 73.9296 45.6025 73.7305 45.668C73.6524 45.6966 73.5698 45.7109 73.4867 45.7105Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.9727 36.4066C26.2572 35.1076 29.9942 35.7821 32.6174 38.1472L44.1653 48.5588C44.4915 48.8529 44.5175 49.3557 44.2234 49.6819C43.9293 50.008 43.4265 50.0341 43.1003 49.74L31.5525 39.3284C29.3779 37.3678 26.2803 36.8088 23.5576 37.8856L17.8952 40.1249C17.4063 40.3183 17.1447 40.852 17.2916 41.3569C17.454 41.9156 18.0505 42.2251 18.6008 42.0362L24.2159 40.1086C25.5709 39.6435 27.0722 39.9787 28.1007 40.9759L29.8931 42.7138C30.3273 43.1348 30.2517 43.8513 29.7394 44.1725L20.7253 49.8231C20.3532 50.0564 19.8624 49.9438 19.6292 49.5717C19.3959 49.1996 19.5085 48.7089 19.8806 48.4756L28.1831 43.271L26.9936 42.1177C26.3949 41.5372 25.521 41.3421 24.7323 41.6128L19.1172 43.5404C17.708 44.0242 16.1804 43.2317 15.7644 41.801C15.3884 40.5079 16.0582 39.1412 17.3104 38.646L22.9727 36.4066Z"
                fill="var(--svg-gray1-gray3)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M42.9952 34.773C41.1687 34.773 39.688 36.2537 39.688 38.0801C39.688 39.9066 41.1687 41.3873 42.9952 41.3873C44.8216 41.3873 46.3023 39.9066 46.3023 38.0801C46.3023 36.2537 44.8216 34.773 42.9952 34.773ZM38.0977 38.0801C38.0977 35.3753 40.2903 33.1826 42.9952 33.1826C45.7 33.1826 47.8927 35.3753 47.8927 38.0801C47.8927 40.785 45.7 42.9776 42.9952 42.9776C40.2903 42.9776 38.0977 40.785 38.0977 38.0801Z"
                fill="var(--svg-gray1-gray3)"
            />
        </svg>
    );
};
